import { getDomainConfigBackend } from "@helpers/config";
import { checkCookies, getCookie, setCookies } from "cookies-next";
export const CORRELATION_ID = "correlationId";

export const getAssetsByType = (data: BannerType[], typeName: string) => {
  return data?.filter((f) => f.code === typeName);
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getheaders = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  // console.info("userToken", getCookie("userToken", { req, res }));
  var userid_simplica = "C-hwBTwi";
  var pwd_simplica = "321f260fda7af2ea6c83a5aa0ee4d7caf5bd6b57";
  var auth_simplica = userid_simplica + ":" + pwd_simplica;
  var token_simplica = "Basic " + Buffer.from(auth_simplica).toString("base64");
  console.info("Basic", token_simplica);

  let HeaderData = {
    track_id: uuidv4(),
    origin: req.headers["origin"],
    api_key: String(getCookie("userToken", { req, res })), //req.headers["api_key"],
    "Content-Type": "application/json",
    referer1: req.headers["referer1"] || "",
    referer: req.headers["referer"] || "",
    usr_type: req.headers["usr_type"] || "",
    //'trav_id': req.headers['trav_id'],
    "X-Forwarded-For": req.headers["X-Forwarded-For"] || "",
    "true-client-ip": req.headers["true-client-ip"] || "",
    client_ip_address: req.headers["true-client-ip"] || "",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};

export const getCorrelationId = (_req: any, _res: any) => {
  const correlationId = checkCookies("correlationId", { req: _req, res: _res })
    ? getCookie("correlationId", { req: _req, res: _res })
    : "";
  return String(correlationId);
};
export const setCorrelationId = (_req: any, _res: any) => {
  const correlationId = uuidv4();
  setCookies("correlationId", correlationId, { req: _req, res: _res });
  return correlationId;
};

export const getheadersOccupancy = (_req: any, _res: any) => {
  const config = getDomainConfigBackend(_req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: _req, res: _res }) || "{}")
  );
  const correlationId = getCorrelationId(_req, _res);
  let HeaderData = {
    origin: _req.headers?.origin,
    referer: _req.headers?.referer || "",
    referer1: _req.headers?.referer || "",
    usr_type: 2,
    user_type: 2,
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    "Content-Type": "application/json",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    client_ip_address:
      getCookie("true-client-ip", { req: _req, res: _res }) || "",
    "true-client-ip":
      getCookie("true-client-ip", { req: _req, res: _res }) || "",
    correlation_id: correlationId,
    "correlation-id": correlationId,
    org_id:
      getCookie("orgid", { req: _req, res: _res }) ||
      getCookie("wlOrg", { req: _req, res: _res }) ||
      "",
    product_type: getCookie("product_type", { req: _req, res: _res }) || "",
  };

  //console.info("ads getheadersOccupancy ===> ", HeaderData);
  return HeaderData;
};

export const getheadersGeneral = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    usr_type: 2,
    origin: req.headers.origin,
    // origin: String(req.headers.origin),
    referer1: req.headers["referer1"] || "",
    referer: req.headers.origin /* req.headers["referer"] || "" */,
    client_ip_address: String(getCookie("ip", { req: req, res: res })) || "",
    "true-client-ip": String(getCookie("ip", { req: req, res: res })) || "",
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    "Content-Type": "application/json",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    correlation_id: getCorrelationId(req, res),
    "correlation-id": getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  /* console.info({ HeaderData }); */
  return HeaderData;
};
export const getheadersDateFormat = (req: any, res: any) => {
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    client_ip_address: String(getCookie("ip", { req: req, res: res })) || "",
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "",
  };
  return HeaderData;
};

export const getheadersFlightSearch = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    "Content-Type": "application/json",
    origin: req.headers.origin,
    referer: req.body.json.referer ? req.body.json.referer : req.headers.origin,
    referer1: req.body.json.referer
      ? req.body.json.referer
      : req.headers.origin,
    client_ip_address: String(getCookie("ip", { req: req, res: res })) || "",
    "true-client-ip": String(getCookie("ip", { req: req, res: res })) || "",
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    unique_id: req?.body?.json?.unique_id || "",
    uid: req?.body?.json?.unique_id || "",
    usr_type: 2,
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};

export const getheadersFlightTraveler = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    "Content-Type": "application/json",
    track_id: uuidv4(),
    origin: req.headers.origin,
    referer: req.headers.origin,
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "",
    user_id: dataUser?.userId ? dataUser?.userId : "",
    usr_type: 2,
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};

export const getheadersCarsSearch = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    "Content-Type": "application/json",
    origin: req.headers.origin,
    referer1: req.headers["referer1"] || "",
    referer: req.headers.referer,
    client_ip_address: String(getCookie("ip", { req: req, res: res })) || "",
    "true-client-ip": String(getCookie("ip", { req: req, res: res })) || "",
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    unique_id: req?.body?.unique_id || "",
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};
export const getApiKeyBackEnd = (req: any, res: any) => {
  return { api_key: String(getCookie("userToken", { req, res })) || "" };
};

export const getheadersForRecentSearch = (req: any, res: any) => {
  //const config = getDomainConfigBackend(req);
  // console.info("userToken", getCookie("userToken", { req, res }));
  var userid_simplica = "C-hwBTwi";
  var pwd_simplica = "321f260fda7af2ea6c83a5aa0ee4d7caf5bd6b57";
  var auth_simplica = userid_simplica + ":" + pwd_simplica;
  var token_simplica = "Basic " + Buffer.from(auth_simplica).toString("base64");
  console.info("Basic", token_simplica);

  let HeaderData = {
    track_id: uuidv4(),
    origin: req.headers["origin"],
    api_key: String(getCookie("userToken", { req, res })), //req.headers["api_key"],
    "Content-Type": "application/json",
    referer1: req.headers["referer1"] || "",
    referer: req.headers["referer"] || "",
    usr_type: req.headers["usr_type"] || "",
    //'trav_id': req.headers['trav_id'],
    "X-Forwarded-For": req.headers["X-Forwarded-For"] || "",
    "true-client-ip": req.headers["true-client-ip"] || "",
    client_ip_address: req.headers["true-client-ip"] || "",
    Authorization:
      "Basic " +
      Buffer.from("C-gc426p:4b3e328551b03125f623ffe028ec1855fc78ca0e").toString(
        "base64"
      ),
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};

export const getheadersFlightSearchBooking = (req: any, res: any) => {
  const config = getDomainConfigBackend(req);
  const dataUser = JSON.parse(
    String(getCookie("userData", { req: req, res: res }) || "{}")
  );
  let HeaderData = {
    "Content-Type": "application/json",
    origin: req.headers.origin,
    referer: req?.body?.json?.referer,
    referer1: req?.body?.json?.referer,
    client_ip_address: String(getCookie("ip", { req: req, res: res })) || "",
    "true-client-ip": String(getCookie("ip", { req: req, res: res })) || "",
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    uid: req?.body?.json?.unique_id || "",
    usr_type: 2,
    correlation_id: getCorrelationId(req, res),
    org_id:
      getCookie("orgid", { req: req, res: res }) ||
      getCookie("wlOrg", { req: req, res: res }) ||
      "",
    product_type: getCookie("product_type", { req: req, res: res }) || "",
  };
  return HeaderData;
};

export const replaceSpecialCharsInventoryId = (urlPath: string) => {
  if (urlPath) {
    let count = 0;
    let init = 0;

    let position = urlPath.indexOf("/");
    const end = urlPath.indexOf("?wl=");
    while (position != -1) {
      count++;
      position = urlPath.indexOf("/", position + 1);
      if (count == 3) {
        init = position + 1;
      }
    }
    const inventoryId = urlPath.substring(init, end);
    let newInventoryId = inventoryId.replaceAll("/", "!");
    newInventoryId = newInventoryId.replaceAll(" ", "+");
    const size = urlPath.length;
    const path = `${urlPath.substring(
      0,
      init
    )}${newInventoryId}${urlPath.substring(end, size)}`;

    return path;
  }
  return urlPath;
};

export const daysBetweenTwoDates = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    // Convertir las fechas a objetos tipo Date
    const fechaInicioObj: any = new Date(startDate);
    const fechaFinObj: any = new Date(endDate);

    // Calcular la diferencia en milisegundos
    const diferenciaMs = fechaFinObj - fechaInicioObj;

    // Convertir la diferencia en milisegundos a días
    const dias = diferenciaMs / (1000 * 60 * 60 * 24);

    // Redondear el resultado para obtener un número entero de días
    return Math.round(dias);
  }

  return 0;
};

export const getPriceInDays = (price: number, daysNum: number) => {
  const priceInDays = price / daysNum;

  return Number(priceInDays.toFixed(2)).toLocaleString("en-US");
};

export const getBedsWeeks = (sleepsString?: string) => {
  try {
    const beds = sleepsString?.match(/-?\d+(\.\d+)?/g) as string[];
    return Number(beds[1]) / 2;
  } catch (error) {
    return 2;
  }
};

export const getheadersPlans = (_req: any, _res: any) => {
  const config = getDomainConfigBackend(_req);
  const checkUser = checkCookies("userData", { req: _req, res: _res });
  let dataUser = JSON.parse(
    String(getCookie("userData", { req: _req, res: _res }) || "{}")
  );

  if (!checkUser) {
    if (checkCookies("userDataTmp", { req: _req, res: _res })) {
      let dataUserTmp = JSON.parse(
        String(getCookie("userDataTmp", { req: _req, res: _res }) || "{}")
      );

      dataUser = { ...dataUser, ...dataUserTmp };
    }
  }
  let HeaderData = {
    origin: _req.headers?.origin,
    referer: _req.headers?.referer,
    referer1: _req.headers?.referer,
    usr_type: 2,
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    "Content-Type": "application/json",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    client_ip_address:
      getCookie("true-client-ip", { req: _req, res: _res }) || "",
    "true-client-ip":
      getCookie("true-client-ip", { req: _req, res: _res }) || "",
    correlation_id: getCorrelationId(_req, _res),
    org_id:
      getCookie("orgid", { req: _req, res: _res }) ||
      getCookie("wlOrg", { req: _req, res: _res }) ||
      "",
    product_type: getCookie("product_type", { req: _req, res: _res }) || "",
  };

  console.info("ads getheadersPlans ===> ", HeaderData);
  return HeaderData;
};

export const autoScrollToAElementID = (id: string) => {
  const element = document.getElementById(id || "") as HTMLElement;
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};
export const getTranslateKeyMandatoryElementBooking = (
  label: weeksFormBookingMandatoryTravFields
) => {
  let translateKey: string = "";
  if (label == "trav_title") {
    translateKey = "PLEASE SELECT TITLE";
  }
  if (label == "trav_firstname") {
    translateKey = "PLEASE ENTER NAME";
  }
  if (label == "trav_lastname") {
    translateKey = "PLEASE ENTER LAST NAME";
  }
  if (label == "trav_email") {
    translateKey = "PLEASE ENTER VALID E-MAIL ADDRESS";
  }
  if (label == "trav_country_code") {
    translateKey = "PLEASE SELECT THE COUNTRY CODE";
  }
  if (label == "trav_phone") {
    translateKey = "PLEASE ENTER PHONE NUMBER";
  }
  if (label == "PLEASE SELECT A TRAVELER") {
    translateKey = "NEW TRAVELER INFORMATION MISSING";
  }
  return translateKey;
};
export const validateMandatoryElementBooking = (
  travelerFormSelected: WeeksTravelerForm | null
) => {
  let requiredMandatoryElement: weeksFormBookingMandatoryTravFields[] = [];

  if (travelerFormSelected) {
    if (!travelerFormSelected.trav_title) {
      requiredMandatoryElement.push("trav_title");
    }
    if (!travelerFormSelected.trav_firstname) {
      requiredMandatoryElement.push("trav_firstname");
    }
    if (!travelerFormSelected.trav_lastname) {
      requiredMandatoryElement.push("trav_lastname");
    }
    if (!travelerFormSelected.trav_email) {
      requiredMandatoryElement.push("trav_email");
    }
    if (!travelerFormSelected.trav_country_code) {
      requiredMandatoryElement.push("trav_country_code");
    }
    if (!travelerFormSelected.trav_phone) {
      requiredMandatoryElement.push("trav_phone");
    }
  } else {
    requiredMandatoryElement.push("PLEASE SELECT A TRAVELER");
  }

  return requiredMandatoryElement;
};
export const addProductypeDesition = (
  service: bookingHistoryDropDownOptionsValueType,
  products: any[],
  myTripsWindowPreview?: boolean
) => {
  const availableProyectType: any[] = [
    "HLT",
    "FLT",
    "CAR",
    "RST",
    "CRU",
    "TRF",
    "TRA",
    "ACT",
  ];
  let isAdded: boolean = false;
  let newService: string =
    service == "HLT" || service == "HTL" ? "HTL" : service;
  if (service == "ALL") {
    isAdded = true;
  } else {
    const findElement: any = products?.find((x) => x?.code == newService);
    if (findElement) {
      isAdded = true;
    } else {
      if (newService == "HTL") {
        const findElement: any = products?.find((x) => x?.code == "HLT");
        if (findElement) {
          isAdded = true;
          newService = "HLT";
        }
      }
      if (newService == "TRF") {
        const findElement: any = products?.find((x) => x?.code == "TRA");
        if (findElement) {
          isAdded = true;
          newService = "TRF";
        }
      }
    }
  }
  if (myTripsWindowPreview && isAdded) {
    const find = availableProyectType.find((x) => x == newService);
    if (!find) {
      isAdded = false;
    }
  }
  return isAdded;
};

export const getheadersServerSR = (
  cookies: Partial<{
    [key: string]: string;
  }>,
  url: string
) => {
  const config = getDomainConfigBackend({
    headers: {
      origin: url,
      referer: url,
    },
  });
  const dataUser = cookies["userData"] ? JSON.parse(cookies["userData"]) : {};
  const correlationId = cookies["correlationId"] || uuidv4();
  const trueClientIp = cookies["true-client-ip"] || "";
  const orgId = cookies["orgid"] || cookies["wlOrg"] || "";
  const productType = cookies["product_type"] || "";
  let HeaderData = {
    origin: url,
    referer: url || "",
    referer1: url || "",
    usr_type: 2,
    user_type: 2,
    track_id: uuidv4(),
    api_key: dataUser?.auth?.api_token ? dataUser?.auth?.api_token : "", //req.headers["api_key"],
    user_id: dataUser?.userId ? dataUser?.userId : "",
    "Content-Type": "application/json",
    Authorization:
      "Basic " + Buffer.from(String(config?.authHash)).toString("base64"),
    client_ip_address: trueClientIp,
    "true-client-ip": trueClientIp,
    correlation_id: correlationId,
    "correlation-id": correlationId,
    org_id: orgId,
    product_type: productType,
  };

  //console.info("ads getheadersOccupancy ===> ", HeaderData);
  return HeaderData;
};
