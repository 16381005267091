import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Moment } from "moment";
import { useRouter } from "next/router";
import {
  getParamsFromQuery,
  getParamsFromQueryDetails,
  getParamsFromQueryForMenu,
  getParamsFromQueryForProductDetail,
} from "@helpers/helperActivities";
import { getDateToFormat } from "@helpers/helper";
import { INIT_CHECKIN, INIT_CHECKOUT } from "@data/consts";
type CreateTravelerUniqIdType = {
  travelerId: number;
  userId: number;
} | null;
type ActivityContextType = {
  pickupText: string;
  bookingSearchId: string;
  dates: ActivityDateType;
  destinationText: string;
  destinationId: number;
  productCode: string;
  productOptionCode: string;
  startTime: string;
  bookProductOption: ActivityProductCheckItemType | null;
  pax: ActivitiesPaxType | null;
  location: string;
  locations: ActivitylocationType[];
  pickupLocation: ActivitylocationType | null;
  menuRequest: ActivityMenuRequestType | null;
  availabilityRequest: ActivityAvailabilityRequestType | null;
  checkRequest: CheckReqType | null;
  destinationTextForCard: string;
  // book section
  pickupStatus: boolean;
  languageGuide: ActivityLanguageGuidesType | null;
  travelerForm: ActivitiesTravelerFormType[];
  specialRequest: string;
  bookingQuestions: any;
  timestamp: number;
  timestampTraveler: number;
  // end book section
  couponCode: ApplyCouponCodeType | null;
  activitieCashAmount: PaymentMethodCashAmountCars | null;
  travelerRequest: ActivityTravelerRequestType;
  currentUniqueId: CreateTravelerUniqIdType;
  showModalPayment: boolean;
  bookMessage: string;
  travelerFormall: ActivitiesTravelerFormType[];
  pickupDropoffLocation: ActivitylocationDropoffType | null;
  pickupDropoffText: string;
  pickupDropoffStatus: boolean;
  setPickupDropoffStatus: (val: boolean) => void;
  setPickupDropoffText: (val: string) => void;
  setPickupDropoffLocation: (val: ActivitylocationDropoffType) => void;
  setTravelerFormall: (val: ActivitiesTravelerFormType[]) => void;
  setTravelerRequest: (value: ActivityTravelerRequestType) => void;
  setActivitieCashAmount: (data: PaymentMethodCashAmountCars | null) => void;
  setCouponCode: (value: ApplyCouponCodeType | null) => void;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: "checkIn" | "checkOut", date: Date | Moment) => void;
  setDestinationText: (val: string) => void;
  setDestinationId: (val: number) => void;
  setProductCode: (val: string) => void;
  setProductOptionCode: (val: string) => void;
  setStartTime: (val: string) => void;
  setPax: (paxConfig: ActivitiesPaxType | null) => void;
  getCheckIn: (format?: string, autoLocale?: boolean) => string;
  getCheckOut: (format?: string, autoLocale?: boolean) => string;
  getPaxReq: () => PaxReqType[];
  setBookProductOption: (val: ActivityProductCheckItemType | null) => void;
  setLocationsArray: (data: ActivitylocationType[]) => void;
  setPickupLocation: (val: ActivitylocationType) => void;
  setMenuRequest: (val: ActivityMenuRequestType | null) => void;
  setAvailabilityRequest: (val: ActivityAvailabilityRequestType | null) => void;
  setCheckRequest: (val: CheckReqType | null) => void;
  setLanguageGuide: (val: ActivityLanguageGuidesType | null) => void;
  setPickupStatus: (val: boolean) => void;
  setTravelerForm: (val: ActivitiesTravelerFormType[]) => void;
  setSpecialRequest: (val: string) => void;
  setDestinationTextForCard: (val: string) => void;
  setBookingSearchId: (val: string) => void;
  setBookingQuestions: (val: any) => void;
  setTimestamp: (val: number) => void;
  setPickupText: (val: string) => void;
  setTimestampTraveler: (val: number) => void;
  setCurrentUniqueId: (val: CreateTravelerUniqIdType) => void;
  setShowModalPayment: (val: boolean) => void;
  setBookMessage: (val: string) => void;
};

const ActivityContextDefaultValues: ActivityContextType = {
  bookingSearchId: "",
  pickupText: "",
  dates: {
    checkIn: INIT_CHECKIN,
    checkOut: INIT_CHECKOUT,
  },
  destinationText: "",
  destinationTextForCard: "",
  destinationId: 0,
  productCode: "",
  productOptionCode: "",
  pax: null,
  location: "",
  bookProductOption: null,
  locations: [],
  pickupLocation: null,
  menuRequest: null,
  availabilityRequest: null,
  startTime: "",
  checkRequest: null,
  // book section
  pickupStatus: false,
  languageGuide: null,
  travelerForm: [],
  bookingQuestions: null,
  specialRequest: "",
  // end book section
  couponCode: null,
  activitieCashAmount: null,
  timestamp: Date.now(),
  timestampTraveler: Date.now(),
  travelerRequest: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    dateOfBirth: "",
    phone: "",
  },
  currentUniqueId: null,
  showModalPayment: false,
  bookMessage: "",
  travelerFormall: [],
  pickupDropoffLocation: null,
  pickupDropoffText: "",
  pickupDropoffStatus: false,
  setPickupDropoffStatus: () => {},
  setPickupDropoffText: () => {},
  setPickupDropoffLocation: () => {},
  setTravelerFormall: () => {},
  setTravelerRequest: () => {},
  setTimestamp: () => {},
  setTimestampTraveler: () => {},
  setActivitieCashAmount: () => {},
  setCouponCode: () => {},
  setDate: () => {},
  setDates: () => {},
  setDestinationText: () => {},
  setDestinationId: () => {},
  setProductCode: () => {},
  setProductOptionCode: () => {},
  getCheckIn: () => "",
  getCheckOut: () => "",
  setPax: () => {},
  getPaxReq: () => [],
  setBookProductOption: () => {},
  setLocationsArray: () => {},
  setPickupLocation: () => {},
  setMenuRequest: () => {},
  setAvailabilityRequest: () => {},
  setStartTime: () => {},
  setCheckRequest: () => {},
  setLanguageGuide: () => {},
  setPickupStatus: () => {},
  setTravelerForm: () => {},
  setSpecialRequest: () => {},
  setDestinationTextForCard: () => {},
  setBookingSearchId: () => {},
  setBookingQuestions: () => {},
  setPickupText: () => {},
  setCurrentUniqueId: () => {},
  setShowModalPayment: () => {},
  setBookMessage: () => {},
};

const ActivityContext = createContext<ActivityContextType>(
  ActivityContextDefaultValues
);

export function useActivity() {
  return useContext(ActivityContext);
}

type Props = {
  children: ReactNode;
};

export function ActivityProvider({ children }: Props) {
  const router = useRouter();
  // * states declare
  const [travelerRequest, setTravelerRequestData] =
    useState<ActivityTravelerRequestType>(
      ActivityContextDefaultValues.travelerRequest
    );

  const [timestamp, setTimestampState] = useState<number>(
    ActivityContextDefaultValues.timestamp
  );
  const [dates, setDateInfo] = useState<ActivityDateType>(
    ActivityContextDefaultValues.dates
  );
  const [destinationText, setDestinationTextState] = useState<string>(
    ActivityContextDefaultValues.destinationText
  );
  const [destinationId, setDestinationIdState] = useState(
    ActivityContextDefaultValues.destinationId
  );
  const [productCode, setProductCodeState] = useState(
    ActivityContextDefaultValues.productCode
  );
  const [startTime, setStartTimeState] = useState(
    ActivityContextDefaultValues.startTime
  );
  const [productOptionCode, setProductOptionCodeState] = useState(
    ActivityContextDefaultValues.productOptionCode
  );
  const [bookProductOption, setBookProductOptionState] = useState(
    ActivityContextDefaultValues.bookProductOption
  );
  const [pax, setPaxConfigInfo] = useState<ActivitiesPaxType | null>(
    ActivityContextDefaultValues.pax
  );
  const [menuRequest, setMenuRequestState] =
    useState<ActivityMenuRequestType | null>(
      ActivityContextDefaultValues.menuRequest
    );
  const [availabilityRequest, setAvailabilityRequestState] =
    useState<ActivityAvailabilityRequestType | null>(
      ActivityContextDefaultValues.availabilityRequest
    );
  const [checkRequest, setCheckRequestState] = useState<CheckReqType | null>(
    ActivityContextDefaultValues.checkRequest
  );

  const [locations, setLocations] = useState<ActivitylocationType[]>(
    ActivityContextDefaultValues.locations
  );

  const [languageGuide, setLanguageGuideState] =
    useState<ActivityLanguageGuidesType | null>(
      ActivityContextDefaultValues.languageGuide
    );
  const [pickupStatus, setPickupStatusState] = useState<boolean>(
    ActivityContextDefaultValues.pickupStatus
  );
  const [travelerForm, setTravelerFormState] = useState<
    ActivitiesTravelerFormType[]
  >(ActivityContextDefaultValues.travelerForm);
  const [specialRequest, setSpecialRequestState] = useState<string>(
    ActivityContextDefaultValues.specialRequest
  );
  const [destinationTextForCard, setDestinationTextForCardState] =
    useState<string>(ActivityContextDefaultValues.destinationTextForCard);

  const [pickupLocation, setPickupLocationState] = useState(
    ActivityContextDefaultValues.pickupLocation
  );

  const [bookingSearchId, setBookingSearchIdState] = useState(
    ActivityContextDefaultValues.bookingSearchId
  );

  const [bookingQuestions, setBookingQuestionsState] = useState(
    ActivityContextDefaultValues.bookingQuestions
  );
  const [couponCode, setCouponCode] = useState<ApplyCouponCodeType | null>(
    ActivityContextDefaultValues.couponCode
  );
  const [activitieCashAmount, setActivitieCashAmount] =
    useState<PaymentMethodCashAmountCars | null>(
      ActivityContextDefaultValues.activitieCashAmount
    );

  const [pickupText, setPickupTextState] = useState<string>(
    ActivityContextDefaultValues.pickupText
  );

  const [timestampTraveler, setTimestampTravelerState] = useState<number>(
    ActivityContextDefaultValues.timestampTraveler
  );
  const [currentUniqueId, setCurrentUniqueIdState] =
    useState<CreateTravelerUniqIdType>(
      ActivityContextDefaultValues.currentUniqueId
    );
  const [showModalPayment, setShowModalPaymentState] = useState<boolean>(
    ActivityContextDefaultValues.showModalPayment
  );
  const [bookMessage, setBookMessageState] = useState<string>(
    ActivityContextDefaultValues.bookMessage
  );
  const [travelerFormall, settravelerFormallState] = useState<
    ActivitiesTravelerFormType[]
  >(ActivityContextDefaultValues.travelerFormall);
  const [pickupDropoffLocation, setPickupDropoffLocationState] = useState(
    ActivityContextDefaultValues.pickupDropoffLocation
  );
  const [pickupDropoffText, setPickupDropoffTextState] = useState<string>(
    ActivityContextDefaultValues.pickupDropoffText
  );
  const [pickupDropoffStatus, setPickupDropoffStatusState] = useState<boolean>(
    ActivityContextDefaultValues.pickupDropoffStatus
  );
  // * end states declare
  // * start methods
  const setTravelerFormall = (val: ActivitiesTravelerFormType[]) => {
    settravelerFormallState(val);
  };
  const setTravelerRequest = (data: ActivityTravelerRequestType) => {
    setTravelerRequestData(data);
  };

  const setTimestamp = (data: number) => {
    setTimestampState(data);
  };

  const setPickupLocation = (data: ActivitylocationType | null) => {
    setPickupLocationState(data);
  };

  const setLocationsArray = (data: ActivitylocationType[]) => {
    setLocations(data);
  };

  const setDate = (key: "checkIn" | "checkOut", date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key]: date,
    });
  };

  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      checkIn: date1,
      checkOut: date2,
    });
  };

  const setDestinationText = (val: string) => {
    setDestinationTextState(val);
  };

  const setDestinationId = (val: number) => {
    setDestinationIdState(val);
  };

  const setProductCode = (val: string) => {
    setProductCodeState(val);
  };
  const setStartTime = (val: string) => {
    setStartTimeState(val);
  };
  const setProductOptionCode = (val: string) => {
    setProductOptionCodeState(val);
  };
  const setBookProductOption = (val: ActivityProductCheckItemType | null) => {
    setBookProductOptionState(val);
  };

  const setMenuRequest = (val: ActivityMenuRequestType | null) => {
    setMenuRequestState(val);
  };

  const setAvailabilityRequest = (
    val: ActivityAvailabilityRequestType | null
  ) => {
    setAvailabilityRequestState(val);
  };
  const setCheckRequest = (val: CheckReqType | null) => {
    setCheckRequestState(val);
  };
  const setPickupStatus = (val: boolean) => {
    setPickupStatusState(val);
  };
  const setLanguageGuide = (val: ActivityLanguageGuidesType | null) => {
    setLanguageGuideState(val);
  };
  const setTravelerForm = (val: ActivitiesTravelerFormType[]) => {
    setTravelerFormState(val);
  };
  const setSpecialRequest = (val: string) => {
    setSpecialRequestState(val);
  };

  const setDestinationTextForCard = (val: string) => {
    setDestinationTextForCardState(val);
  };

  const setBookingSearchId = (val: string) => {
    setBookingSearchIdState(val);
  };

  const setBookingQuestions = (val: any[]) => {
    setBookingQuestionsState(val);
  };
  const setPickupText = (val: string) => {
    setPickupTextState(val);
  };
  const setTimestampTraveler = (val: number) => {
    setTimestampTravelerState(val);
  };

  const setShowModalPayment = (val: boolean) => {
    setShowModalPaymentState(val);
  };
  const setBookMessage = (val: string) => {
    setBookMessageState(val);
  };
  const setPickupDropoffLocation = (data: ActivitylocationType | null) => {
    setPickupDropoffLocationState(data);
  };
  const setPickupDropoffStatus = (val: boolean) => {
    setPickupDropoffStatusState(val);
  };
  const getPaxReq = () => {
    const arrayAux = [];
    for (const key in pax) {
      if (Object.prototype.hasOwnProperty.call(pax, key)) {
        const element = pax[key];
        arrayAux.push({
          ageBand: key,
          numberOfTravelers: element,
        });
      }
    }

    if (arrayAux.length == 0) {
      return [
        {
          ageBand: "ADULT",
          numberOfTravelers: 1,
        },
      ];
    }

    return arrayAux;
  };

  const getCheckIn = (format = "YYYY-MM-DD", autoLocale = false) =>
    getDateToFormat(dates.checkIn, format, autoLocale);

  const getCheckOut = (format = "YYYY-MM-DD", autoLocale = false) =>
    getDateToFormat(dates.checkOut, format, autoLocale);

  const setPax = (paxConfig: ActivitiesPaxType | null) => {
    setPaxConfigInfo(paxConfig);
  };

  const setCurrentUniqueId = (val: CreateTravelerUniqIdType) => {
    setCurrentUniqueIdState(val);
  };
  const setPickupDropoffText = (val: string) => {
    setPickupDropoffTextState(val);
  };
  // * start methods

  // * the states is updated when the url changes
  useEffect(() => {
    const searchDetailPage = ["/activities/search-details"];
    const pageDetail = ["/activities/details", "/activities/checkout"];
    if (searchDetailPage.includes(router.pathname)) {
      console.log("useeffect search-details");

      const query = router.query;
      const params = getParamsFromQuery(query);
      const manuParams = getParamsFromQueryForMenu(query);
      setDestinationText(params.destinationText);
      setDestinationTextForCard(params.destinationText);

      setDestinationId(params.destination);
      setDates(params.checkIn, params.checkOut);
      setMenuRequest(manuParams);
    } else if (pageDetail.includes(router.pathname)) {
      console.log("useeffect details");
      const query = router.query;
      const params = getParamsFromQueryDetails(query);
      setProductCode(params.productCode);
      setDestinationText(params.destinationText);
      setDestinationId(params.destination);
      setDates(params.checkIn, params.checkOut);
      if (params.pax) {
        setPax(params.pax);
      } else {
        setPax(null);
      }
      if (params.productOptionCode) {
        setProductOptionCode(params.productOptionCode);
      }
      if (params.startTime) {
        setStartTime(params.startTime);
      }

      const availabilityReq = getParamsFromQueryForProductDetail(query);
      setTimestampState(Date.now());
      setBookProductOption(null);
      setAvailabilityRequest(availabilityReq);
      setTimeout(() => {
        setCheckRequest({
          productCode: params.productCode,
          travelDate: getDateToFormat(params.checkIn, "YYYY-MM-DD", false),
          paxMix: getPaxReq(),
        });
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const [location] = useState(ActivityContextDefaultValues.location);

  const value = {
    dates,
    destinationText,
    destinationId,
    location,
    productCode,
    productOptionCode,
    bookProductOption,
    pax,
    locations,
    pickupLocation,
    menuRequest,
    availabilityRequest,
    startTime,
    checkRequest,
    pickupStatus,
    languageGuide,
    travelerForm,
    specialRequest,
    destinationTextForCard,
    bookingSearchId,
    bookingQuestions,
    timestamp,
    couponCode,
    activitieCashAmount,
    travelerRequest,
    pickupText,
    timestampTraveler,
    currentUniqueId,
    showModalPayment,
    bookMessage,
    travelerFormall,
    pickupDropoffLocation,
    pickupDropoffText,
    pickupDropoffStatus,
    setPickupDropoffStatus,
    setPickupDropoffText,
    setPickupDropoffLocation,
    setTravelerFormall,
    setTravelerRequest,
    setActivitieCashAmount,
    setCouponCode,
    setCheckRequest,
    setStartTime,
    setDate,
    setDates,
    setDestinationText,
    setDestinationId,
    setProductCode,
    setProductOptionCode,
    setBookProductOption,
    getCheckIn,
    getCheckOut,
    setPax,
    getPaxReq,
    setMenuRequest,
    setLocationsArray,
    setAvailabilityRequest,
    setLanguageGuide,
    setPickupStatus,
    setTravelerForm,
    setSpecialRequest,
    setPickupLocation,
    setDestinationTextForCard,
    setBookingSearchId,
    setBookingQuestions,
    setTimestamp,
    setPickupText,
    setTimestampTraveler,
    setCurrentUniqueId,
    setShowModalPayment,
    setBookMessage,
  };

  ActivityContext.displayName = "ActivityContext";
  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
}
