import { createContext, ReactNode, useContext, useState } from "react";

type HolidayStaysSearchDetailContextType = {
  isAutomaticSearch: boolean;
  filterRequest: HolidayStaysRequestFilterType;
  setFilterAmenities: (value: number[]) => void;
  setFilterAttractions: (
    value: HolidayStaysRequestFilterAttractionsType
  ) => void;
  setFilterCustomerRating: (value: number[]) => void;
  setFilterChainId: (value: number[]) => void;
  setFilterHotelId: (value: number[]) => void;
  setFilterHotelName: (value: string[]) => void;
  setFilterHotelType: (value: string) => void;
  setFilterMinimumPrice: (value: number) => void;
  setFilterMaximumPrice: (value: number) => void;
  setFilterPrice: (max: number, min: number) => void;
  setFilterOffset: (value: number) => void;
  setFilterPropertyTypes: (value: number[]) => void;
  setFilterSortBy: (value: string) => void;
  setFilterSortOn: (value: string) => void;
  setFilterStarRating: (value: number[]) => void;
  setFilterThemes: (value: number[]) => void;
  setNowSearch: (value: boolean) => void;
  setFilterLimit: (value: number) => void;
  setFilterSort: (sortBy: string, sortOn: string) => void;
  setFilterAttractionsLatLong: (value: string) => void;
  setFilterAttractionsDistance: (value: number | string) => void;
  setFilterHotelIdName: (id: number[], name: string[]) => void;
};

const HolidayStaysSearchDetailDefaultValues: HolidayStaysSearchDetailContextType =
  {
    filterRequest: {
      amenities: [],
      attractions: {
        distance: "",
        id: "",
        lat_long: "",
      },
      chain_id: [],
      customer_rating: [],
      hotel_id: [],
      hotel_name: [],
      hotel_type: null,
      limit: 50,
      maximum_price: 0,
      minimum_price: 0,
      offset: 0,
      property_types: [],
      sort_by: "",
      sort_on: "",
      star_rating: [],
      themes: [],
      discount_range: [
        {
          name: "holiday_stay",
          from: 0,
          to: 100,
        },
        {
          name: "great_deal",
          from: 41,
          to: 100,
        },
        {
          name: "deals",
          from: 11,
          to: 40,
        },
        {
          name: "offers",
          from: 1,
          to: 10,
        },
      ],
    },
    isAutomaticSearch: true,
    setNowSearch: () => {},
    setFilterAmenities: () => {},
    setFilterAttractions: () => {},
    setFilterCustomerRating: () => {},
    setFilterChainId: () => {},
    setFilterHotelId: () => {},
    setFilterHotelName: () => {},
    setFilterHotelType: () => {},
    setFilterMinimumPrice: () => {},
    setFilterMaximumPrice: () => {},
    setFilterOffset: () => {},
    setFilterPropertyTypes: () => {},
    setFilterSortBy: () => {},
    setFilterSortOn: () => {},
    setFilterStarRating: () => {},
    setFilterThemes: () => {},
    setFilterPrice: () => {},
    setFilterLimit: () => {},
    setFilterSort: () => {},
    setFilterAttractionsLatLong: () => {},
    setFilterAttractionsDistance: () => {},
    setFilterHotelIdName: () => {},
  };

const HolidayStaysSearchDetailContext =
  createContext<HolidayStaysSearchDetailContextType>(
    HolidayStaysSearchDetailDefaultValues
  );

export function useHolidayStaysRequestFilter() {
  return useContext(HolidayStaysSearchDetailContext);
}

type Props = {
  children: ReactNode;
};

export function HolidayStaysSearchDetailProvider({ children }: Props) {
  const [isAutomaticSearch, setIsAutomaticSearch] = useState(true);
  const [filterRequest, setFilterRequestData] =
    useState<HolidayStaysRequestFilterType>(
      HolidayStaysSearchDetailDefaultValues.filterRequest
    );

  const setNowSearch = (flag: boolean) => {
    setIsAutomaticSearch(flag);
  };

  const setFilterRequest = (data: HolidayStaysRequestFilterType) => {
    setFilterRequestData(data);
  };

  const setFilterAmenities = (amenties: number[]) => {
    setFilterRequestData({ ...filterRequest, amenities: amenties });
  };

  const setFilterAttractions = (
    value: HolidayStaysRequestFilterAttractionsType
  ) => {
    setFilterRequestData({ ...filterRequest, attractions: value });
  };

  //used
  const setFilterCustomerRating = (value: number[]) => {
    // const tmpValue = { ...filterRequest.customer_rating, value };
    setFilterRequestData({ ...filterRequest, customer_rating: value });
  };

  const setFilterChainId = (value: number[]) => {
    const tmpValue = { ...filterRequest.chain_id, value };
    setFilterRequestData({ ...filterRequest, chain_id: tmpValue });
  };

  const setFilterHotelId = (value: number[]) => {
    const tmpValue = { ...filterRequest.hotel_id, value };
    setFilterRequestData({ ...filterRequest, hotel_id: tmpValue });
  };

  const setFilterHotelName = (value: string[]) => {
    setFilterRequestData({ ...filterRequest, hotel_name: value });
  };

  const setFilterHotelIdName = (id: number[], name: string[]) => {
    setFilterRequest({
      ...filterRequest,
      hotel_id: id,
      hotel_name: name,
    });
  };

  const setFilterHotelType = (value: string) => {
    setFilterRequestData({ ...filterRequest, hotel_type: value });
  };

  const setFilterMinimumPrice = (value: number) => {
    setFilterRequestData({ ...filterRequest, minimum_price: value });
  };

  const setFilterMaximumPrice = (value: number) => {
    setFilterRequestData({ ...filterRequest, maximum_price: value });
  };

  const setFilterOffset = (value: number) => {
    setFilterRequestData({ ...filterRequest, offset: value });
  };

  const setFilterPropertyTypes = (value: number[]) => {
    const tmpValue = { ...filterRequest.property_types, value };
    setFilterRequestData({ ...filterRequest, property_types: tmpValue });
  };

  const setFilterSortBy = (value: string) => {
    setFilterRequestData({ ...filterRequest, sort_by: value });
  };

  const setFilterSortOn = (value: string) => {
    setFilterRequestData({ ...filterRequest, sort_on: value });
  };

  //used
  const setFilterStarRating = (value: number[]) => {
    setFilterRequestData({ ...filterRequest, star_rating: value });
  };

  const setFilterThemes = (value: number[]) => {
    // const tmpAmenities = { ...filterRequest.themes, value };
    setFilterRequestData({ ...filterRequest, themes: value });
  };

  const setFilterSort = (sortBy: string, sortOn: string) => {
    setFilterRequestData({
      ...filterRequest,
      sort_on: sortOn,
      sort_by: sortBy,
    });
  };

  const setFilterAttractionsLatLong = (coordinates: string) => {
    setFilterRequestData({
      ...filterRequest,
      attractions: {
        ...filterRequest.attractions,
        lat_long: coordinates,
      },
    });
  };

  const setFilterAttractionsDistance = (distance: string | number) => {
    setFilterRequestData({
      ...filterRequest,
      attractions: {
        ...filterRequest.attractions,
        distance: distance,
      },
    });
  };
  //used
  const setFilterPrice = (max: number, min: number) => {
    setFilterRequestData({
      ...filterRequest,
      maximum_price: max,
      minimum_price: min,
    });
  };

  const setFilterLimit = (value: number) => {
    setFilterRequestData({
      ...filterRequest,
      offset: value,
    });
  };
  const value = {
    filterRequest,
    isAutomaticSearch,
    setNowSearch,
    setFilterRequest,
    setFilterAmenities,
    setFilterAttractions,
    setFilterCustomerRating,
    setFilterChainId,
    setFilterHotelId,
    setFilterHotelName,
    setFilterHotelType,
    setFilterMinimumPrice,
    setFilterMaximumPrice,
    setFilterOffset,
    setFilterPropertyTypes,
    setFilterSortBy,
    setFilterSortOn,
    setFilterStarRating,
    setFilterThemes,
    setFilterPrice,
    setFilterLimit,
    setFilterSort,
    setFilterAttractionsLatLong,
    setFilterAttractionsDistance,
    setFilterHotelIdName,
  };

  HolidayStaysSearchDetailContext.displayName =
    "HolidayStaysSearchDetailContext";

  return (
    <HolidayStaysSearchDetailContext.Provider value={value}>
      {children}
    </HolidayStaysSearchDetailContext.Provider>
  );
}
