import { addDays } from "date-fns";

// "1950-01-01"
export const DAY = 1;
export const MONTH = 2;
export const YEAR = 0;
// "1950-01-01"
export const FORMAT1_DAY = 2;
export const FORMAT1_MONTH = 1;
export const FORMAT1_YEAR = 0;

export const DEF_DAY = 2;
export const DEF_MONTH = 1;
export const DEF_YEAR = 0;

export const INIT_CHECKIN = addDays(new Date(), 1);
export const INIT_CHECKOUT = addDays(new Date(), 2);

export const hotelAmenities = [
  {
    pref_id: 7,
    pref_name_identifier: "Restaurant",
    pref_name: "Restaurant",
    isSelected: true,
  },
  {
    pref_id: 1,
    pref_name_identifier: "Bar",
    pref_name: "Bar",
    isSelected: true,
  },
  {
    pref_id: 31,
    pref_name_identifier: "Breakfast",
    pref_name: "Breakfast",
    isSelected: true,
  },
  {
    pref_id: 49,
    pref_name_identifier: "Pool",
    pref_name: "Pool",
    isSelected: true,
  },
  {
    pref_id: 4,
    pref_name_identifier: "Gym",
    pref_name: "Gym",
    isSelected: true,
  },
  {
    pref_id: 44,
    pref_name_identifier: "Business Center",
    pref_name: "Business Center",
    isSelected: true,
  },
  // {
  //     pref_name: 'Day Care',
  //     isSelected: true
  // },
  {
    pref_id: 37,
    pref_name_identifier: "Spa",
    pref_name: "Spa",
    isSelected: true,
  },
];

export const MODE_TYPE_HOTEL = "hotel";
export const MODE_TYPE_FLIGHT = "flight";
export const MODE_TYPE_CAR = "car";
export const MODE_TYPE_HOLIDAY_STAY = "holidayStay";
export const MODE_TYPE_WEEK = "week";
export const MODE_TYPE_ACTIVITY = "activity";
export const MODE_TYPE_TRANSFER = "transfer";

// this const is used in hotel checkout
export const PROCESSING = "Processing";
export const PRICE_CHANGE = "PriceChange";
export const BOOKED = "Confirmed";
export const ERROR = "Failed";
export const PENDING = "Pending";

export const DISTANCE_DATA = [
  {
    value: "5",
    label: "5 Km",
  },
  {
    value: "10",
    label: "10 Km",
  },
  {
    value: "15",
    label: "15 Km",
  },
  {
    value: "20",
    label: "20 Km",
  },
];

export const WITH_TAX = "With Tax";
export const WITHOUT_TAX = "Without Tax";

export const FLOWADS1 = "flow1";
export const FLOWADS2 = "flow2";
export const MAX_PAX = 10;
export const MAX_CHILDS = 4;
