export const light = {
  primary: "#434573",
  background: "#edf2f6",
  card: "#fff",
  nav: "#f8f8f8",
  border: "#deebf1",
  purple: ["#434573", "#5C5C7F", "#A7A7C4"],
  gray: ["#585858", "#818181"],
  checkout: {
    card: "#fff",
    border: "#deebf1",
    purple: ["#434573", "#5C5C7F", "#A7A7C4"],
    gray: ["#585858", "#818181", "#212529", "#6c757d"],
  },
};
