import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  FC,
  useEffect,
} from "react";
import * as authHelper from "@helpers/AuthHelpers";
import {
  checkCookies,
  getCookie,
  removeCookies,
  setCookies,
} from "cookies-next";
import { getUser } from "@helpers/AuthHelpers";

export const AUTH_ROLE_GUEST = "Guest";
export const AUTH_ROLE_CLIENT = "Client";

type AuthContextProps = {
  auth: AuthModelType | undefined;
  saveAuth: (auth: AuthModelType | undefined) => void;
  saveUser: (user: UserModelType | undefined) => void;
  currentUser: UserModelType | undefined;
  reloadCss: number;
  setReloadCss: () => void;
  setCurrentUser: Dispatch<SetStateAction<UserModelType | undefined>>;
  logout: () => void;
  isLogged: boolean;
  setCookiesAuth: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  currentUser: authHelper.getUser(),
  saveAuth: () => {},
  saveUser: () => {},
  setCurrentUser: () => {},
  logout: () => {},
  reloadCss: 0,
  setReloadCss: () => {},
  isLogged: authHelper.getIsLogged(),
  setCookiesAuth: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};
const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModelType | undefined>(
    authHelper.getAuth()
  );
  const [currentUser, setCurrentUser] = useState<UserModelType | undefined>(
    authHelper.getUser()
  );
  const [isLogged, setIsLogged] = useState(authHelper.getIsLogged());

  const [reloadCss, setReloadCssVal] = useState(0);

  const setReloadCss = () => {
    setReloadCssVal(Math.random());
  };

  const saveAuth = (auth: AuthModelType | undefined) => {
    setAuth(auth);
    if (auth) {
      setIsLogged(true);
      authHelper.setAuth(auth);
    } else {
      setIsLogged(false);
      authHelper.removeAuth();
    }
  };

  const getRefactorUserData = (user: UserModelType | undefined) => {
    if (user) {
      return {
        userName: user.userName,
        userToken: user.userToken,
        personalPicture: user.personalPicture,
        email: user.email,
        currency_code: user.currency_code,
        language: user.language,
        nationality: user.nationality,
        userId: user.userId,
        role: user.role,
        auth: user.auth,
        first_Name: user.first_Name,
        last_name: user.last_name,
        currency: user.currency,
      };
    }
  };

  const saveUser = (user: UserModelType | undefined) => {
    setCurrentUser(user);
    console.log("🚀 ~ file: AuthContext.tsx:59 ~ saveUser ~ user:", user);
    if (user) {
      setIsLogged(true);
      authHelper.setUser(user);
      setCookies("userData", JSON.stringify(getRefactorUserData(user)));
      setCookies("userDataTmp", JSON.stringify(getRefactorUserData(user)));
    } else {
      setIsLogged(false);
      authHelper.removeAuth();
      removeCookies("userData");
      removeCookies("userDataTmp");
      removeCookies("myReservationToken");
    }

    setReloadCss();
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    removeCookies("userToken");
    removeCookies("userData");
    removeCookies("userDataTmp");
    removeCookies("userGeolocation");
    removeCookies("myReservationToken");
  };

  const setCookiesAuth = () => {
    const cookieUserDataExist = getCookie("userData");
    const cookieTokenExist = getCookie("userToken");
    const getUserData = getUser();
    if (!cookieUserDataExist && getUserData) {
      setCookies(
        "userData",
        JSON.stringify({
          auth: {
            api_token: getUserData.auth.api_token,
          },
          userId: getUserData.userId,
        })
      );
    }
    if (!cookieTokenExist && getUserData) {
      setCookies("userToken", getUserData?.auth.api_token);
    }
  };

  useEffect(() => {
    if (currentUser && !checkCookies("userData")) {
      setCookies("userData", JSON.stringify(currentUser));
      setCookies("userDataTmp", JSON.stringify(currentUser));
    }
    return () => {};
  }, [currentUser]);

  AuthContext.displayName = "AuthContext";

  return (
    <AuthContext.Provider
      value={{
        auth,
        currentUser,
        isLogged,
        reloadCss,
        saveAuth,
        setCurrentUser,
        saveUser,
        logout,
        setReloadCss,
        setCookiesAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// const AuthInit: FC = ({ children }) => {
//   const { auth, logout, setCurrentUser,currentUser } = useAuth();
//   const didRequest = useRef(false);
//   const [showSplashScreen, setShowSplashScreen] = useState(true);
//   // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
//   useEffect(() => {
//     const requestUser = async () => {
//       try {
//         if (didRequest.current) {
//           // const {data} = await getUserByToken(apiToken)
//           // const {data} = await getUserByToken(apiToken)
//           console.log(auth,currentUser);

//           // if (currentUser) {
//           //   setCurrentUser(currentUser)
//           // }
//         }
//       } catch (error) {
//         console.error(error);
//         if (!didRequest.current) {
//           logout();
//         }
//       } finally {
//         setShowSplashScreen(false);
//       }

//       return () => (didRequest.current = true);
//     };

//     if (auth && auth.api_token) {
//       requestUser();
//       console.log('currentUser',currentUser,auth);

//       setCurrentUser(currentUser)
//     } else {
//       logout();
//       setShowSplashScreen(false);
//     }
//     // eslint-disable-next-line
//   }, []);

//   return showSplashScreen ? <>loading</> : <>{children}</>;
// };

export { AuthProvider, useAuth };
