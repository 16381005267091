const AUTH_LOCAL_STORAGE_KEY = "auth-react";
const AUTH_LOCAL_STORAGE_USER = "auth-react-user";
const NATIONALITY_KEY = "nationality";
const AUTH_LOCAL_STORAGE_CALENDAR_START_DAY = "calendar-day";
export const AUTH_LOCAL_STORAGE_USER_DATE_FORMAT = "user-date-format";

const getAuth = (): AuthModelType | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModelType = JSON.parse(lsValue) as AuthModelType;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};
const getUser = (): UserModelType | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_USER);
  if (!lsValue) {
    return;
  }

  try {
    const user: UserModelType = JSON.parse(lsValue) as UserModelType;
    if (user) {
      // You can easily check user_token expiration also
      return user;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModelType) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const setUser = (user: UserModelType) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(user);
    localStorage.setItem(AUTH_LOCAL_STORAGE_USER, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};
const removeAuth = () => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem(AUTH_LOCAL_STORAGE_USER);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export const setupAxios = (axios: any) => {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: {
      headers: {
        Authorization: string;
        token: string;
        user_id: string;
        trav_id: string;
      };
    }) => {
      const auth = getAuth();
      const user = getUser();
      if (auth && auth.api_token) {
        // config.headers.Authorization = `Bearer ${auth.api_token}`;
        config.headers.token = `${auth.api_token}`;
        config.headers.user_id = `${user?.userId}`;
        config.headers.trav_id = `${user?.userId}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
};
const getIsLogged = (): boolean => {
  if (typeof window == "undefined" || !localStorage) {
    return false;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return false;
  }

  try {
    const auth: AuthModelType = JSON.parse(lsValue) as AuthModelType;
    if (auth) {
      // You can easily check auth_token expiration also
      return true;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    return false;
  }
  return false;
};

const getNationality = (): string => {
  if (typeof window == "undefined" || !localStorage) {
    return "US";
  }

  const value: string | null = localStorage.getItem(NATIONALITY_KEY);
  if (!value) {
    return "US";
  }

  return value;
};

const setCalendarStartDayLocalStorage = (day: number) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_CALENDAR_START_DAY, String(day));
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getCalendarStartDayLocalStorage = (): number => {
  if (typeof window == "undefined" || !localStorage) {
    return -1;
  }

  const value: string | null = localStorage.getItem(
    AUTH_LOCAL_STORAGE_CALENDAR_START_DAY
  );
  if (!value) {
    return -1;
  }

  return Number(value);
};

const setUserDateFormatStorage = (day: string) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_USER_DATE_FORMAT, day);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getUserDateFormatStorage = (): string => {
  if (typeof window == "undefined" || !localStorage) {
    return "DD/MM/YYYY";
  }

  const value: string | null = localStorage.getItem(
    AUTH_LOCAL_STORAGE_USER_DATE_FORMAT
  );
  if (!value) {
    return "";
  }

  return value;
};

export {
  getAuth,
  setAuth,
  removeAuth,
  getUser,
  setUser,
  getIsLogged,
  getNationality,
  setCalendarStartDayLocalStorage,
  getCalendarStartDayLocalStorage,
  setUserDateFormatStorage,
  getUserDateFormatStorage,
  AUTH_LOCAL_STORAGE_KEY,
  AUTH_LOCAL_STORAGE_USER,
  NATIONALITY_KEY,
};
