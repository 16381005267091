export const flightOptions = [
  { id: 1, value: "One Way", label: "One Way" },
  { id: 2, value: "Round Trip", label: "Round Trip" },
];

export const classOptions = [
  { id: 1, name: "cabin", value: "Economy", label: "Economy" },
  { id: 4, name: "cabin", value: "Premium Economy", label: "Premium Economy" },
  { id: 2, name: "cabin", value: "Business", label: "Business" },
  { id: 3, name: "cabin", value: "First Class", label: "First Class" },
];

export const BagsOptions = [
  { id: 1, value: "0 Bags", label: "0 Bags", data: 0 },
  { id: 2, value: "1 Bags", label: "1 Bags", data: 1 },
  { id: 3, value: "2 Bags", label: "2 Bags", data: 2 },
  { id: 4, value: "3 Bags", label: "3 Bags", data: 3 },
];

export const DaysDropDown = [
  { id: 1, value: "exact", label: "EXACT", add2Traduction: "", req: "exact" },
  {
    id: 2,
    value: "+ day after",
    label: "DAY AFTER",
    add2Traduction: "+",
    req: "day_after",
  },
  {
    id: 3,
    value: "+ day before",
    label: "DAY BEFORE",
    add2Traduction: "+",
    req: "day_before",
  },
  {
    id: 4,
    value: "± 1 day",
    label: "DAY",
    add2Traduction: "± 1",
    req: "1_day",
  },
  {
    id: 5,
    value: "± 2 days",
    label: "DAYS",
    add2Traduction: "± 2",
    req: "2_day",
  },
  {
    id: 6,
    value: "± 3 days",
    label: "DAYS",
    add2Traduction: "± 3",
    req: "3_day",
  },
];
