import { createContext, ReactNode, useContext, useState } from "react";

type LoyaltytContextType = {
  loyaltyProgramByUser: InfoLoyaltyProgramSelectedType[];
  setLoyaltyProgramByUser: (obj: InfoLoyaltyProgramSelectedType[]) => void;
  stopLoyaltyRequest: boolean;
  setStopLoyaltyRequest: (obj: boolean) => void;
  loyaltyProgramCheckOut: LoyaltyProgramRewardsCheckoutType[];
  setLoyaltyProgramCheckOut: (
    data: LoyaltyProgramRewardsCheckoutType[]
  ) => void;
  executeAutoLoadInfoLoyalty: boolean;
  setExecuteAutoLoadInfoLoyalty: (value: boolean) => void;
  loyaltyExecutionMessage: string;
  setLoyaltyExecutionMessage: (value: string) => void;
  loyaltyExecutionProgress: number;
  setLoyaltyExecutionProgress: (value: number) => void;
  loyaltyEditProgress: string;
  setLoyaltyEditProgress: (value: string) => void;
};

const LoyaltyProgramContextDefaultValues: LoyaltytContextType = {
  loyaltyProgramByUser: [],
  setLoyaltyProgramByUser: () => {},
  stopLoyaltyRequest: false,
  setStopLoyaltyRequest: () => {},
  loyaltyProgramCheckOut: [],
  setLoyaltyProgramCheckOut: () => {},
  executeAutoLoadInfoLoyalty: false,
  setExecuteAutoLoadInfoLoyalty: () => {},
  loyaltyExecutionMessage: "",
  setLoyaltyExecutionMessage: () => {},
  loyaltyExecutionProgress: 0,
  setLoyaltyExecutionProgress: () => {},
  loyaltyEditProgress: "",
  setLoyaltyEditProgress: () => {},
};

const LoyaltyProgramContext = createContext<LoyaltytContextType>(
  LoyaltyProgramContextDefaultValues
);

export function useLoyaltyProgram() {
  return useContext(LoyaltyProgramContext);
}

type Props = {
  children: ReactNode;
};

export function LoyaltyProgramProvider({ children }: Props) {
  const [loyaltyProgramByUser, setLoyaltyProgramByUser] = useState(
    LoyaltyProgramContextDefaultValues.loyaltyProgramByUser
  );
  const handlesetLoyaltyProgramByUser = (
    obj: InfoLoyaltyProgramSelectedType[]
  ) => {
    setLoyaltyProgramByUser(obj);
  };
  const [stopLoyaltyRequest, setStopLoyaltyRequest] = useState(
    LoyaltyProgramContextDefaultValues.stopLoyaltyRequest
  );
  const [loyaltyProgramCheckOut, setLoyaltyProgramCheckOut] = useState(
    LoyaltyProgramContextDefaultValues.loyaltyProgramCheckOut
  );
  const [executeAutoLoadInfoLoyalty, setExecuteAutoLoadInfoLoyalty] = useState(
    LoyaltyProgramContextDefaultValues.executeAutoLoadInfoLoyalty
  );
  const [loyaltyExecutionMessage, setLoyaltyExecutionMessage] = useState(
    LoyaltyProgramContextDefaultValues.loyaltyExecutionMessage
  );
  const [loyaltyExecutionProgress, setLoyaltyExecutionProgress] = useState(
    LoyaltyProgramContextDefaultValues.loyaltyExecutionProgress
  );
  const [loyaltyEditProgress, setLoyaltyEditProgress] = useState(
    LoyaltyProgramContextDefaultValues.loyaltyEditProgress
  );
  const value = {
    loyaltyProgramByUser,
    setLoyaltyProgramByUser,
    handlesetLoyaltyProgramByUser,
    stopLoyaltyRequest,
    setStopLoyaltyRequest,
    loyaltyProgramCheckOut,
    setLoyaltyProgramCheckOut,
    executeAutoLoadInfoLoyalty,
    setExecuteAutoLoadInfoLoyalty,
    loyaltyExecutionMessage,
    setLoyaltyExecutionMessage,
    loyaltyExecutionProgress,
    setLoyaltyExecutionProgress,
    loyaltyEditProgress,
    setLoyaltyEditProgress,
  };

  LoyaltyProgramContext.displayName = "LoyaltyProgramContext";
  return (
    <LoyaltyProgramContext.Provider value={value}>
      {children}
    </LoyaltyProgramContext.Provider>
  );
}
