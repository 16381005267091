import { NextApiHandler } from "next";
import axios from "axios";
import { getheadersOccupancy } from "@lib/helpers";
import { logError, logTitle } from "@data/console/colors-log";
import { getDomainConfigBackend } from "@helpers/config";
import { checkCookies, getCookie } from "cookies-next";
import { callExternalConfig } from "./getExternalConfig";

export const getConfigApi = async (req: any, res: any) => {
  logTitle("getConfig Ativities api");
  const config = getDomainConfigBackend(req);

  const body = req.body;
  let orgId = "";
  const langCodeCookie = checkCookies("lan_code", { req, res })
    ? getCookie("lan_code", { req, res })
    : null;
  const lang = body.lang ? body.lang : langCodeCookie;
  const siteId = config?.siteID;

  if (config?.orgId) {
    orgId = String(config?.orgId);
  }
  let headers: any = {
    site_id: req?.headers?.siteId || "",
    ...getheadersOccupancy(req, res),
  };

  const userId = headers.user_id;

  headers = {
    ...headers,
    org_id: orgId,
    user_id: userId,
    site_id: siteId,
    lang_code: lang || "en-US",
    "browser-info": body.browser || "",
  };

  const bodyData = {
    siteLevel: 1,
    ...body,
    keyWord: "Global",
    pageCode: "Global",
    orgId: orgId,
    org_id: orgId,
    langCode: lang || "en-US",
    user_id: userId,
  };
  console.info("getConfig headers >>>>>>>>>>>>>>>>>>", headers);
  console.info("getConfig body > pageCode >>>>>>>>>>>>>>>>>>", bodyData);
  const url = `${config?.activitiesBussinesPath}/v1/layout/config`;
  const resp = await axios.post(
    url,
    {
      ...bodyData,
    },
    { headers }
  );
  console.log("getConfig resp >>>>>", resp.data);
  return resp.data;
};

const getConfig: NextApiHandler<any> = async (req, res) => {
  logTitle("getConfig Ativities api");
  if (req.method !== "POST") {
    // Not supported method
    res.status(405).end();
  }

  try {
    const resp = await getConfigApi(req, res);
    const orgId = resp?.data?.orgBySite?.orgId;
    let externalConfig = null;
    if (resp?.data?.orgBySite?.orgId) {
      externalConfig = await callExternalConfig(orgId, req);
    }
    let output = { config: resp.data, externalConfig: externalConfig?.data };
    console.log('getConfig output ',JSON.stringify(output));
    return res
      .status(200)
      .json(output);
  } catch (error: any) {
    logError("getConfig api", error);
    res.status(302).json(error);
  }
};

export default getConfig;
