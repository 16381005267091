import currencyList from "@data/CurrencyList.json";
const WITHOUT_TAX = "Without Tax";
const WITH_TAX = "With Tax";
/* Calculations Prices With or With out Tax */
export const calculatePerNightPrice = (
  useTax: string,
  hotelData?: RoomPriceType,
  noOfNights = 1
) => {
  let finalPrice: number = 0;
  if (useTax == WITHOUT_TAX) {
    finalPrice = Number(hotelData?.per_night || 0);
  }
  if (useTax == WITH_TAX || !useTax) {
    const roomTax = Number(hotelData?.room_tax || 0) / noOfNights;
    let tmpPrice: any = Number(hotelData?.per_night || 0) + roomTax;
    tmpPrice = tmpPrice % 1 > 0 ? tmpPrice.toFixed(2) : tmpPrice.toFixed(0);
    finalPrice = Number(tmpPrice);
  }
  return finalPrice;
};

export const calculateTotalPrice = (
  useTax: string,
  hotelData?: RoomPriceType
) => {
  let finalPrice: number = 0;
  if (useTax == WITHOUT_TAX) {
    finalPrice = Number(hotelData?.net_rate || 0);
  }
  if (useTax == WITH_TAX || !useTax) {
    finalPrice = Number(hotelData?.inclusive || 0);
  }
  return finalPrice;
};

export const calculateTotalPriceV2 = (
  useTax: boolean | null,
  hotelData?: PriceType
) => {
  let finalPrice: number = 0;
  if (!useTax) {
    finalPrice = Number(hotelData?.net_rate || 0);
  }
  if (useTax) {
    finalPrice = Number(hotelData?.inclusive || 0);
  }
  return finalPrice;
};

export const getCurrencySimbol = (code: string) => {
  const symb = currencyList.filter((entry) =>
    Object.values(entry).some(
      (val) => typeof val === "string" && val.match(code)
    )
  );

  return symb.length > 0 ? symb[0].symbol : "$";
};

export const calculatePriceStrikeFromExcInc = (
  currentRoom: RoomType | undefined | null,
  taxPercent = 0,
  useTax: string
) => {
  const stikePrice = Number(currentRoom?.price.strike_price || 0);
  const stikePriceTax = Number(currentRoom?.price.strike_tax || 0);

  if (stikePriceTax == 0 && taxPercent > 0) {
    const stikePriceNewTax = Number(stikePrice) * (1 - taxPercent);
    const stikePriceNew = Number(stikePrice) - stikePriceTax;
    let total = 0;
    if (useTax == WITHOUT_TAX) {
      total = stikePriceNew;
    }
    if (useTax == WITH_TAX) {
      total = stikePriceNew + stikePriceNewTax;
    }
    return { stikePriceNewTax, stikePriceNew, total };
  }
  if (stikePrice > 0 && stikePriceTax > 0) {
    let total = 0;
    if (useTax == WITHOUT_TAX) {
      total = stikePrice;
    }
    if (useTax == WITH_TAX) {
      total = stikePriceTax + stikePrice;
    }
    return {
      stikePriceNewTax: stikePriceTax,
      stikePriceNew: stikePrice,
      total,
    };
  }
  return { stikePriceNewTax: 0, stikePriceNew: 0, total: 0 };
};
export const calculatePriceStrike = (
  useTax: string,
  currentRoom: RoomType | undefined | null,
  refunValue: string,
  personalWalletON?: boolean,
  taxPercent = 0
) => {
  try {
    let finalPrice: number = 0;
    let tmpFinalPrice: any = 0;
    let b2c_net_rate = 0;
    let b2c_tax = 0;
    let refundable: boolean = refunValue == "refundable" ? true : false;
    let supplier_code = currentRoom?.supplier_code;

    const supplierPrice = currentRoom?.supplier_price?.find(
      (supplier) =>
        supplier?.supplier_code == supplier_code &&
        currentRoom?.is_refundable == refundable &&
        supplier.b2c_net_rate >= 0 &&
        supplier.room_name == currentRoom.name
    );

    // currentRoom?.supplier_price?.map((supplier: any) => {
    if (supplierPrice) {
      b2c_net_rate = Number(supplierPrice?.b2c_net_rate);
      b2c_tax = Number(supplierPrice?.b2c_tax);
    }
    // });

    if (useTax == WITHOUT_TAX) {
      finalPrice = Number(b2c_net_rate);
    }
    if (useTax == WITH_TAX || !useTax) {
      finalPrice = Number(b2c_net_rate) + Number(b2c_tax);
    }
    const noOfNight = currentRoom?.no_of_nights || 1;
    finalPrice = finalPrice / (personalWalletON ? 1 : noOfNight);

    tmpFinalPrice =
      finalPrice % 1 > 0 ? finalPrice.toFixed(2) : finalPrice.toFixed(0);

    finalPrice = Number(tmpFinalPrice);
    const newPrices = calculatePriceStrikeFromExcInc(
      currentRoom,
      taxPercent,
      useTax
    );
    if (newPrices.stikePriceNew > 0 && newPrices.stikePriceNewTax > 0) {
      finalPrice = newPrices.total / noOfNight;
    }

    return finalPrice;
  } catch (error) {
    console.info("calculatePriceStrike", error);
    return 0;
  }
};

export const calculateStikePriceMyTrips = (
  useTax: string,
  hotelData: PriceType,
  noOfRooms = 1
) => {
  let finalStrikePrice: number = 0;
  let tmpFinaleStrikePrice: any = null;
  if (useTax == WITHOUT_TAX) {
    finalStrikePrice = Number(hotelData?.strike_price || 0);
  }
  if (useTax == WITH_TAX || !useTax) {
    tmpFinaleStrikePrice =
      Number(hotelData?.strike_price || 0) + Number(hotelData?.strike_tax || 0);
    tmpFinaleStrikePrice =
      tmpFinaleStrikePrice % 1 > 0
        ? tmpFinaleStrikePrice.toFixed(2)
        : tmpFinaleStrikePrice.toFixed(0);
    finalStrikePrice = Number(tmpFinaleStrikePrice);
  }
  finalStrikePrice = Math.round(finalStrikePrice);
  return finalStrikePrice * noOfRooms;
};

export const calculatePerNightPriceMyTrips = (
  useTax: string,
  hotelData: PriceType,
  noOfRooms = 1,
  noOfNights = 1
) => {
  let finalPrice: number = 0;
  if (useTax == WITHOUT_TAX) {
    finalPrice = Number(hotelData?.per_night || 0);
  }
  if (useTax == WITH_TAX || !useTax) {
    let taxPerNight: number =
      Number(hotelData?.tax || 0) / Number(noOfNights || 1);
    let tmpPrice: any =
      Number(hotelData?.per_night || 0) + Number(taxPerNight || 0);
    tmpPrice = tmpPrice % 1 > 0 ? tmpPrice.toFixed(2) : tmpPrice.toFixed(0);
    finalPrice = Number(tmpPrice);
  }
  finalPrice = Math.round(finalPrice);

  return finalPrice * noOfRooms;
};
