import { ReactNode, useContext, createContext, useState } from "react";

type TrasnferSearchDetailType = {
  trasnfersData: TransferAvailabilityTransferType[];
  totalResults: number;
  dataPaginated: TransferAvailabilityTransferType[];
  offset: number;
  showLoadMore: boolean;
  errorMsg: string;
  filterSelected: string;
  typeFilter: TransferFilterType[];
  supplierFilter: TransferFilterType[];
  minPrice: number;
  maxPrice: number;
  setMinPrice: (amount: number) => void;
  setMaxPrice: (amount: number) => void;
  setSupplierFilter: (filter: TransferFilterType[]) => void;
  setTypeFilter: (filter: TransferFilterType[]) => void;
  setfilterSelected: (flag: string) => void;
  setErrorMsg: (val: string) => void;
  setLoadMore: (flag: boolean) => void;
  setOffset: (total: number) => void;
  setDataPaginated: (data: TransferAvailabilityTransferType[]) => void;
  setTotalResults: (total: number) => void;
  setTransfersData: (data: TransferAvailabilityTransferType[]) => void;
};

const TransferSearchDetailContextDefaultValues: TrasnferSearchDetailType = {
  trasnfersData: [],
  totalResults: 0,
  dataPaginated: [],
  offset: 1,
  showLoadMore: true,
  errorMsg: "",
  filterSelected: "",
  typeFilter: [],
  supplierFilter: [],
  minPrice: 0,
  maxPrice: 0,
  setMinPrice: () => {},
  setMaxPrice: () => {},
  setSupplierFilter: () => {},
  setTypeFilter: () => {},
  setfilterSelected: () => {},
  setErrorMsg: () => {},
  setLoadMore: () => {},
  setOffset: () => {},
  setDataPaginated: () => {},
  setTotalResults: () => {},
  setTransfersData: () => {},
};

const TrasnferSearchDetailContext = createContext<TrasnferSearchDetailType>(
  TransferSearchDetailContextDefaultValues
);

export function useTransferSRP() {
  return useContext(TrasnferSearchDetailContext);
}

type Props = {
  children: ReactNode;
};

export function TransferSearchDetailProvider({ children }: Props) {
  //States
  const [trasnfersData, setTransferInfo] = useState(
    TransferSearchDetailContextDefaultValues.trasnfersData
  );
  const [totalResults, setTotalResultsVal] = useState(
    TransferSearchDetailContextDefaultValues.totalResults
  );

  const [dataPaginated, setDataPagin] = useState(
    TransferSearchDetailContextDefaultValues.dataPaginated
  );

  const [offset, setOffsetValue] = useState(
    TransferSearchDetailContextDefaultValues.offset
  );

  const [showLoadMore, setLoadMoreValue] = useState(
    TransferSearchDetailContextDefaultValues.showLoadMore
  );

  const [errorMsg, setErrorMsgData] = useState(
    TransferSearchDetailContextDefaultValues.errorMsg
  );

  const [filterSelected, setfilterSelectedOpt] = useState(
    TransferSearchDetailContextDefaultValues.filterSelected
  );

  const [typeFilter, setTypeFilterData] = useState(
    TransferSearchDetailContextDefaultValues.typeFilter
  );

  const [supplierFilter, setSupplierFilterData] = useState(
    TransferSearchDetailContextDefaultValues.supplierFilter
  );

  const [minPrice, setMinPriceValue] = useState(
    TransferSearchDetailContextDefaultValues.minPrice
  );

  const [maxPrice, setMaxPriceValue] = useState(
    TransferSearchDetailContextDefaultValues.maxPrice
  );

  //Fill Methods
  const setTransfersData = (data: TransferAvailabilityTransferType[]) => {
    setTransferInfo(data);
  };

  const setTotalResults = (value: number) => {
    setTotalResultsVal(value);
  };

  const setDataPaginated = (data: TransferAvailabilityTransferType[]) => {
    setDataPagin(data);
  };

  const setOffset = (value: number) => {
    setOffsetValue(value);
  };

  const setLoadMore = (value: boolean) => {
    setLoadMoreValue(value);
  };

  const setErrorMsg = (value: string) => {
    setErrorMsgData(value);
  };

  const setfilterSelected = (value: string) => {
    setfilterSelectedOpt(value);
  };

  const setTypeFilter = (value: TransferFilterType[]) => {
    setTypeFilterData(value);
  };

  const setSupplierFilter = (value: TransferFilterType[]) => {
    setSupplierFilterData(value);
  };

  const setMinPrice = (value: number) => {
    setMinPriceValue(value);
  };

  const setMaxPrice = (value: number) => {
    setMaxPriceValue(value);
  };

  const value = {
    trasnfersData,
    totalResults,
    dataPaginated,
    offset,
    showLoadMore,
    errorMsg,
    filterSelected,
    typeFilter,
    supplierFilter,
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    setSupplierFilter,
    setTypeFilter,
    setfilterSelected,
    setErrorMsg,
    setLoadMore,
    setOffset,
    setDataPaginated,
    setTotalResults,
    setTransfersData,
  };

  TrasnferSearchDetailContext.displayName = "TransferSearchDetailContext";

  return (
    <TrasnferSearchDetailContext.Provider value={value}>
      {children}
    </TrasnferSearchDetailContext.Provider>
  );
}
