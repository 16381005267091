import { checkCookies, getCookie } from "cookies-next";
import moment from "moment";

const TRANSFER_REQUEST = "tranRequest";
const TRANSFER_CARD_SELECTED = "transferCardSelected";
const TRANSFER_EXTRA_ADDED = "transExtraAdded";
const TRANSFER_SID = "transSid";
const TRANSFER_BOOK_DATA = "transBookData";
const TRANSFER_PICKUP_IS_AIRPORT = "pickupIsAirport";
const TRANSFER_DROPOFF_IS_AIRPORT = "dropoffIsAirport";
const TRANSFER_COUNTRY_CODE_FROM = "transferCountryCodeFrom";
const TRANSFER_COUNTRY_CODE_TO = "transferCountryCodeTo";
export const setMainData = (data: TransferAvailabilityRequestType | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_REQUEST, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_REQUEST LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getMainData = (): TransferAvailabilityRequestType | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_REQUEST);
  if (!lsValue) {
    return;
  }

  try {
    const headerData: TransferAvailabilityRequestType = JSON.parse(
      lsValue
    ) as TransferAvailabilityRequestType;
    if (headerData) {
      // You can easily check TRANSFER_REQUEST expiration also
      return headerData;
    }
  } catch (error) {
    console.error("TRANSFER_REQUEST LOCAL STORAGE PARSE ERROR", error);
  }
};

// Card Selected storage
export const setCardData = (data: TransferStorageCardDataType) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(data);
    localStorage.setItem(TRANSFER_CARD_SELECTED, lsValue);
  } catch (error) {
    console.error("TRANSFER_CARD_SELECTED LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getCardData = (): TransferStorageCardDataType | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_CARD_SELECTED);
  if (!lsValue) {
    return;
  }

  try {
    const cardData: TransferStorageCardDataType = JSON.parse(
      lsValue
    ) as TransferStorageCardDataType;
    if (cardData) {
      // You can easily check TRANSFER_CARD_SELECTED expiration also
      return cardData;
    }
  } catch (error) {
    console.error("TRANSFER_CARD_SELECTED LOCAL STORAGE PARSE ERROR", error);
  }
};

// Extras Added
export const setAddedExtraData = (data: ExtrasAvailableType[] | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_EXTRA_ADDED, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_EXTRA_ADDED LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getAddedExtraData = (): ExtrasAvailableType[] | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_EXTRA_ADDED);
  if (!lsValue) {
    return;
  }

  try {
    const headerData: ExtrasAvailableType[] = JSON.parse(
      lsValue
    ) as ExtrasAvailableType[];
    if (headerData.length > 0) {
      // You can easily check TRANSFER_EXTRA_ADDED expiration also
      return headerData;
    }
  } catch (error) {
    console.error("TRANSFER_EXTRA_ADDED LOCAL STORAGE PARSE ERROR", error);
  }
};

// Extras SID
export const setSid = (data: string | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_SID, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_SID LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getSid = (): string | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_SID);
  if (!lsValue) {
    return;
  }

  try {
    const headerData: string = JSON.parse(lsValue) as string;
    if (headerData.length > 0) {
      // You can easily check TRANSFER_SID expiration also
      return headerData;
    }
  } catch (error) {
    console.error("TRANSFER_SID LOCAL STORAGE PARSE ERROR", error);
  }
};

// TransferBookResponseType

export const setBookData = (data: TransferBookResponseType | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_BOOK_DATA, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_BOOK_DATA LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getBookData = (): TransferBookResponseType | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_BOOK_DATA);
  if (!lsValue) {
    return;
  }

  try {
    const headerData: TransferBookResponseType = JSON.parse(
      lsValue
    ) as TransferBookResponseType;
    if (headerData) {
      // You can easily check TRANSFER_BOOK_DATA expiration also
      return headerData;
    }
  } catch (error) {
    console.error("TRANSFER_BOOK_DATA LOCAL STORAGE PARSE ERROR", error);
  }
};

// Pickup is Airpot
export const setPickupIsAirpot = (data: boolean | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data != undefined) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_PICKUP_IS_AIRPORT, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_PICKUP_IS_AIRPORT LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getPickupIsAirpot = (): string | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    TRANSFER_PICKUP_IS_AIRPORT
  );
  if (!lsValue) {
    return;
  }

  try {
    const headerData: string = JSON.parse(lsValue) as string;
    console.info(
      "helper header data",
      headerData,
      typeof headerData,
      headerData.length
    );
    if (headerData !== undefined) {
      // You can easily check TRANSFER_PICKUP_IS_AIRPORT expiration also
      return headerData;
    }
  } catch (error) {
    console.error(
      "TRANSFER_PICKUP_IS_AIRPORT LOCAL STORAGE PARSE ERROR",
      error
    );
  }
};
// Pickup is Airpot
export const setDropoffIsAirpot = (data: boolean | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data != undefined) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_DROPOFF_IS_AIRPORT, lsValue);
    }
  } catch (error) {
    console.error(
      "TRANSFER_DROPOFF_IS_AIRPORT LOCAL STORAGE SAVE ERROR",
      error
    );
  }
};

export const getDropoffIsAirpot = (): string | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    TRANSFER_DROPOFF_IS_AIRPORT
  );
  if (!lsValue) {
    return;
  }

  try {
    const headerData: string = JSON.parse(lsValue) as string;
    if (headerData !== undefined) {
      // You can easily check TRANSFER_DROPOFF_IS_AIRPORT expiration also
      return headerData;
    }
  } catch (error) {
    console.error(
      "TRANSFER_DROPOFF_IS_AIRPORT LOCAL STORAGE PARSE ERROR",
      error
    );
  }
};

// CountryCodePickup
export const setCountryCodeFrom = (data: string | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_COUNTRY_CODE_FROM, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_COUNTRY_CODE_FROM LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getCountryCodeFrom = (): string | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    TRANSFER_COUNTRY_CODE_FROM
  );
  if (!lsValue) {
    return;
  }

  try {
    const headerData: string = JSON.parse(lsValue) as string;
    if (headerData.length > 0) {
      // You can easily check TRANSFER_COUNTRY_CODE_FROM expiration also
      return headerData;
    }
  } catch (error) {
    console.error(
      "TRANSFER_COUNTRY_CODE_FROM LOCAL STORAGE PARSE ERROR",
      error
    );
  }
};

// CountryCode dropoff
export const setCountryCodeTo = (data: string | null) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    if (data) {
      const lsValue = JSON.stringify(data);
      localStorage.setItem(TRANSFER_COUNTRY_CODE_TO, lsValue);
    }
  } catch (error) {
    console.error("TRANSFER_COUNTRY_CODE_TO LOCAL STORAGE SAVE ERROR", error);
  }
};

export const getCountryCodeTo = (): string | undefined => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(TRANSFER_COUNTRY_CODE_TO);
  if (!lsValue) {
    return;
  }

  try {
    const headerData: string = JSON.parse(lsValue) as string;
    if (headerData.length > 0) {
      // You can easily check TRANSFER_COUNTRY_CODE_TO expiration also
      return headerData;
    }
  } catch (error) {
    console.error("TRANSFER_COUNTRY_CODE_TO LOCAL STORAGE PARSE ERROR", error);
  }
};

export const transferTypeDictionary = (word: string) => {
  switch (word.toUpperCase()) {
    case "PRIVATE CAR":
      return "PRIVATE";
      break;
    case "ECONOMY_VAN":
      return "ECONOMY VAN";
      break;
    case "ECONOMY_MPV":
      return "ECONOMY MULTI PURPOSE VEHICLE";
      break;
    case "BUSINESS_MPV":
      return "BUSINESS MULTI PURPOSE VEHICLE";
      break;
    case "BUSINESS_VAN":
      return "BUSINESS VAN";
      break;
    case "FIRST":
      return "FIRST CLASS";
      break;
    default:
      return word.toUpperCase();
      break;
  }
};
// CountryCodePickup

export const getQtyOfMatches = (
  key: string,
  data: TransferAvailabilityTransferType[],
  type?: string
) => {
  let qty: number = 0;

  if (data.length > 0) {
    data.map((d) => {
      if (type && type == "supplier") {
        if (d.transfercompany.toUpperCase() == key.toUpperCase()) {
          return qty++;
        }
      } else {
        if (d.vehicle.toUpperCase() == key.toUpperCase()) {
          return qty++;
        }
      }
    });

    return qty;
  }
  return 0;
};

export const buildFilter = (
  data: string[],
  unfilteredData: TransferAvailabilityTransferType[],
  type?: string
) => {
  const filter: TransferFilterChecksType[] = [];
  if (data.length > 0) {
    data.map((d) => {
      if (!filter.some((el) => el.key.toUpperCase() === d.toUpperCase())) {
        filter.push({
          key: transferTypeDictionary(d),
          state: false,
          qty: getQtyOfMatches(d, unfilteredData, type),
        });
      }
    });

    return filter;
  }
  return [];
};

export const getTypesFilter = (data: TransferAvailabilityTransferType[]) => {
  const unique = data
    .map((item) => item.vehicle)
    .filter((value, index, self) => self.indexOf(value) === index);

  return buildFilter(unique, data);
};

export const getSuppliersFilter = (
  data: TransferAvailabilityTransferType[]
) => {
  const unique = data
    .map((item) => item.transfercompany)
    .filter((value, index, self) => self.indexOf(value) === index);

  return buildFilter(unique, data, "supplier");
};

export const getPriceFilter = (data: TransferAvailabilityTransferType[]) => {
  var min = Math.min(...data.map((item) => Number(item.price)));
  var max = Math.max(...data.map((item) => Number(item.price)));

  return {
    max: max,
    min: min,
  };
};

export const getArrayNames = (data: TransferFilterType[]) => {
  let typeArray = data.filter((el) => {
    if (el.state) {
      return data;
    }
  });

  return typeArray.map((item) => item.key);
};

export const sanitizeAirlinesData = (data: TransferAirlinesType[] | null) => {
  let filter: TransferAirlinesCleanedType[] = [];
  if (data && data.length > 0) {
    data.map((d) => {
      filter.push({
        label: d.name,
        value: d.icao_code,
      });
    });
  }

  console.info("filter sanitize", filter);

  return filter;
};

export const getParamsFromQuery = (query: any) => {
  const {
    pickup,
    pickupTime,
    pickupTime2,
    pickupLat,
    pickupLng,
    destination,
    destinationLat,
    destinationLng,
    isReturn,
    adults,
    childrens,
    infants,
    checkIn,
    checkOut,
    transferId,
    pickupCountryCode,
    dropoffCountryCode,
    countryCodeFrom,
    countryCodeTo,
    pickupIsAirport,
    dropoffIsAirport,
  } = query;

  return {
    pickup: String(pickup || ""),
    pickupTime: String(pickupTime || "12:00"),
    pickupTime2: String(pickupTime2 || "12:00"),
    pickupLat: Number(pickupLat || "0"),
    pickupLng: Number(pickupLng || "0"),
    destination: String(destination || ""),
    destinationLat: Number(destinationLat || "0"),
    destinationLng: Number(destinationLng || "0"),
    isReturn: isReturn == "true" ? true : false,
    adults: Number(adults || "1"),
    childrens: Number(childrens || "0"),
    infants: Number(infants || "0"),
    checkIn: moment(checkIn).toDate(),
    checkOut: moment(checkOut).toDate(),
    transferId: transferId,
    pickupCountryCode: pickupCountryCode ?? countryCodeFrom,
    dropoffCountryCode: dropoffCountryCode ?? countryCodeTo,
    pickupIsAirport: Boolean(pickupIsAirport || false),
    dropoffIsAirport: Boolean(dropoffIsAirport || false),
  };
};

export const getColConfigCards = (showMap: boolean) => {
  if (showMap) {
    return {
      sm: {
        order: 2,
        span: 12,
      },
      md: {
        order: 2,
        span: 12,
      },
      lg: {
        order: 2,
        span: 5,
      },
    };
  }
  return {
    lg: {
      order: 1,
      span: 8,
    },
  };
};

export const getColConfigAds = (showMap: boolean) => {
  if (showMap) {
    return {
      lg: {
        order: 1,
        span: 6,
      },
    };
  }
  return {
    lg: {
      order: 2,
      span: 4,
    },
  };
};

export const getHourFormatHoppa = (h: string) => {
  let hour = h.replace(/:/g, "");
  return String(hour).length === 3 ? `0${hour}` : String(hour);
};

export const getRequestSearchFromStates = (
  pickupLat: number,
  pickupLng: number,
  destinationLat: number,
  destinationLng: number,
  pickup: string,
  destination: string,
  isReturn: boolean,
  arrDate: string,
  retDate: string,
  arrTime: string,
  retTime: string,
  adults: number,
  childrens: number,
  infants: number,
  pickupCountry: string,
  dropoffCountry: string
) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";

  return {
    lang: code,
    latitudeP1: pickupLat, // 21.040340423584, // pickupLat,
    longitudeP1: pickupLng, //-86.8735656738281, // pickupLng,
    latitudeP2: destinationLat, //20.2114181518555, //destinationLat,
    longitudeP2: destinationLng, // -87.4653472900391, //destinationLng,
    placeFrom: pickup, //"Cancun Intl. Airport (CUN)",
    placeTo: destination, //"Tulum, Mexico",
    countryCodeFrom: pickupCountry ?? "US",
    countryCodeTo: dropoffCountry ?? "US",
    isReturn: isReturn ? 1 : 0,
    arrDate, //"17/03/23", // moment(dates.checkIn).format("DD/MM/YY"), //"17/03/23",
    arrTime: String(arrTime),
    retDate, // isReturn ? getCheckOut : getCheckIn(), //"17/03/23", //moment(dates.checkIn).format("DD/MM/YY"), //"17/03/23",
    retTime: String(retTime),
    adults: adults ?? 1,
    children: childrens ?? 0,
    infants: infants ?? 0,
    responseType: 0,
    calcJ1PickupTime: 1,
    calcJ2PickupTime: 1,
    googleAPIKey: "",
    iATAj1: "",
    iATAj2: "",
  };
};

export const formatHour = (str: string) => {
  const hour = str.slice(0, 2);
  const minutes = str.slice(2).replace(":", "");
  // const result = [hour, minutes];
  const dayStatus = Number(hour) > 11 && Number(hour) < 23 ? "pm" : "am";
  const formatHour = Number(hour) > 12;
  const realHour = formatHour ? Number(hour) - 12 : Number(hour);
  return `${realHour}:${minutes} ${dayStatus}`;
};

export const paginator = (
  items: any[],
  current_page = 1,
  per_page_items = 10
) => {
  let page = current_page || 1,
    per_page = per_page_items || 10,
    paginatedItems = items?.slice(0, per_page_items * current_page),
    total_pages = Math.ceil(items?.length / per_page);

  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: total_pages > page ? page + 1 : null,
    total: items?.length,
    total_pages: total_pages,
    data: paginatedItems,
  };
};
