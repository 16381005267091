import { addDays } from "date-fns";
import { Moment } from "moment";
import { createContext, ReactNode, useContext, useState } from "react";

type HolidayStayContextType = {
  //* * Search section **//
  dates: {
    checkIn: Date | Moment;
    checkOut: Date | Moment;
  };
  destination_id: number | null;
  search_type: number | null;
  search_text: string | null;
  dest_type: string | null;
  latitude: number | null;
  longitude: number | null;
  paxConfig: GuestConfigurationType[];
  //* * End Search section **//
  bookInfo: BookInfoType;
  travelers: SelectTravelerType[];
  travBookUniqueId: string | undefined;
  currentRoomsSelecctions: CurrentSelectionType[] | undefined;
  currentHolidayStayMinPrice: number;
  hotelResortFilter: PropertyType[];
  scrollWheel: boolean;
  residentFilter: PropertyType[];
  apartamentFilter: PropertyType[];
  resortOtherFilter: PropertyType[];
  othersHotelResortFilter: PropertyType[];
  ijfWeeksActive: boolean;
  search: SearchType;
  setSearch: (search: SearchType) => void;
  setIjfWeeksActive: (flag: boolean) => void;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: "checkIn" | "checkOut", date: Date | Moment) => void;
  setCurrentHolidayStayMinPrice: (val: number) => void;
  setBookInfo: (bookinInfo: BookInfoType) => void;
  setBookSelection: (currentRooms: CurrentSelectionType[]) => void;
  setTraveler: (travelers: SelectTravelerType) => void;
  setTravelers: (travelers: SelectTravelerType[]) => void;
  setTravBookUniqueId: (val: string) => void;
  setDestinationId: (val: number) => void;
  setSearchText: (val: string) => void;
  setLatitude: (val: number) => void;
  setLongitude: (val: number) => void;
  setPaxConfig: (val: GuestConfigurationType[]) => void;
  setDestType: (val: string) => void;
  setSearchType: (val: number) => void;
  setHotelResortFilter: (val: PropertyType[]) => void;
  setScrollWheel: (val: boolean) => void;
  setResidentFilter: (val: PropertyType[]) => void;
  setApartmentFilter: (val: PropertyType[]) => void;
  setOtherFilter: (val: PropertyType[]) => void;
  setOthersHotelResortFilter: (val: PropertyType[]) => void;
};

const holidayStayContextDefaultValues: HolidayStayContextType = {
  dates: {
    checkIn: new Date(),
    checkOut: addDays(new Date(), 1),
  },
  destination_id: null,
  search_type: null,
  search_text: "",
  dest_type: null,
  latitude: null,
  longitude: null,
  paxConfig: [{ room: 1, adult: 2, children: 0, child: [] }],
  bookInfo: {
    roomIds: [],
    couponCode: "",
    usrType: 2,
    cardid: "",
    cashcardAmount: "",
    cashcard: "",
    tripId: "",
    pgAmt: "",
  },
  travBookUniqueId: undefined,
  travelers: [],
  currentHolidayStayMinPrice: 0,
  currentRoomsSelecctions: [],
  hotelResortFilter: [],
  scrollWheel: true,
  residentFilter: [],
  apartamentFilter: [],
  resortOtherFilter: [],
  othersHotelResortFilter: [],
  ijfWeeksActive: false,
  search: {
    dates: {
      checkIn: new Date(),
      checkOut: addDays(new Date(), 1),
    },
    destination_id: null,
    search_type: null,
    search_text: "",
    dest_type: null,
    latitude: null,
    longitude: null,
    paxConfig: [{ room: 1, adult: 2, children: 0, child: [] }],
  },
  setSearch: () => {},
  setDate: () => {},
  setDates: () => {},
  setCurrentHolidayStayMinPrice: () => {},
  setBookSelection: () => {},
  setBookInfo: () => {},
  setTraveler: () => {},
  setTravelers: () => {},
  setTravBookUniqueId: () => {},
  setDestinationId: () => {},
  setSearchText: () => {},
  setLatitude: () => {},
  setLongitude: () => {},
  setPaxConfig: () => {},
  setDestType: () => {},
  setSearchType: () => {},
  setHotelResortFilter: () => {},
  setScrollWheel: () => {},
  setResidentFilter: () => {},
  setApartmentFilter: () => {},
  setOtherFilter: () => {},
  setIjfWeeksActive: () => {},
  setOthersHotelResortFilter: () => {},
};

const HolidayStaysContext = createContext<HolidayStayContextType>(
  holidayStayContextDefaultValues
);

export function useHolidayStay() {
  return useContext(HolidayStaysContext);
}

type Props = {
  children: ReactNode;
};

export function HolidayStayProvider({ children }: Props) {
  const [dates, setDateInfo] = useState<{
    checkIn: Date | Moment;
    checkOut: Date | Moment;
  }>(holidayStayContextDefaultValues.dates);

  const [search, setSearchInfo] = useState<SearchType>(
    holidayStayContextDefaultValues.search
  );

  const [bookInfo, setBookingInfo] = useState<BookInfoType>(
    holidayStayContextDefaultValues.bookInfo
  );

  const [destination_id, setDestinationIdInfo] = useState<number | null>(
    holidayStayContextDefaultValues.destination_id
  );

  const [search_type, setSearchTypeInfo] = useState<number | null>(
    holidayStayContextDefaultValues.search_type
  );

  const [search_text, setSearchTextInfo] = useState<string | null>(
    holidayStayContextDefaultValues.search_text
  );

  const [dest_type, setDestTypeInfo] = useState<string | null>(
    holidayStayContextDefaultValues.dest_type
  );

  const [latitude, setLatitudeInfo] = useState<number | null>(
    holidayStayContextDefaultValues.latitude
  );

  const [longitude, setLongitudeInfo] = useState<number | null>(
    holidayStayContextDefaultValues.longitude
  );

  const [paxConfig, setPaxConfigInfo] = useState<GuestConfigurationType[]>(
    holidayStayContextDefaultValues.paxConfig
  );

  const [travelers, setTravelersInfo] = useState<SelectTravelerType[]>(
    holidayStayContextDefaultValues.travelers
  );
  const [travBookUniqueId, setTravBookUniqueIdInfo] = useState<
    string | undefined
  >(holidayStayContextDefaultValues.travBookUniqueId);

  const [currentRoomsSelecctions, setCurrentSelecctions] = useState<
    CurrentSelectionType[] | undefined
  >(holidayStayContextDefaultValues.currentRoomsSelecctions);

  const [currentHolidayStayMinPrice, setCurrentHolidayStayMinPriceData] =
    useState<number>(
      holidayStayContextDefaultValues.currentHolidayStayMinPrice
    );

  const [hotelResortFilter, setHotelResortFilterData] = useState(
    holidayStayContextDefaultValues.hotelResortFilter
  );

  const [othersHotelResortFilter, setOthersHotelResortFilterData] = useState(
    holidayStayContextDefaultValues.othersHotelResortFilter
  );

  const [scrollWheel, setScrollWheelStatus] = useState(
    holidayStayContextDefaultValues.scrollWheel
  );

  const setScrollWheel = (val: boolean) => {
    setScrollWheelStatus(val);
  };
  const [residentFilter, setResidentFilterData] = useState(
    holidayStayContextDefaultValues.residentFilter
  );

  const [apartamentFilter, setApartamentFilterData] = useState(
    holidayStayContextDefaultValues.residentFilter
  );

  const [resortOtherFilter, setResortOtherFilterData] = useState(
    holidayStayContextDefaultValues.resortOtherFilter
  );

  const [ijfWeeksActive, setIjfWeeksActiveButton] = useState(
    holidayStayContextDefaultValues.ijfWeeksActive
  );

  const setDate = (key: "checkIn" | "checkOut", date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key]: date,
    });
  };

  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      checkIn: date1,
      checkOut: date2,
    });
  };

  const setDestinationId = (val: number) => {
    setDestinationIdInfo(val);
  };

  const setSearchType = (val: number) => {
    setSearchTypeInfo(val);
  };

  const setSearchText = (val: string) => {
    setSearchTextInfo(val);
  };

  const setDestType = (val: string) => {
    setDestTypeInfo(val);
  };

  const setLatitude = (val: number) => {
    setLatitudeInfo(val);
  };

  const setLongitude = (val: number) => {
    setLongitudeInfo(val);
  };

  const setPaxConfig = (val: GuestConfigurationType[]) => {
    setPaxConfigInfo(val);
  };

  const setBookInfo = (bookinInfo: BookInfoType) => {
    setBookingInfo(bookinInfo);
  };

  const setTraveler = (travelerInfo: SelectTravelerType) => {
    setTravelersInfo([...travelers, travelerInfo]);
  };

  const setTravelers = (travelerInfo: SelectTravelerType[]) => {
    setTravelersInfo([...travelerInfo]);
  };

  const setTravBookUniqueId = (val: string) => {
    setTravBookUniqueIdInfo(val);
  };

  const setBookSelection = (currentRooms: CurrentSelectionType[]) => {
    setCurrentSelecctions(currentRooms);
    return currentRooms;
  };

  const setCurrentHolidayStayMinPrice = (val: number) => {
    setCurrentHolidayStayMinPriceData(val);
  };
  const setHotelResortFilter = (val: PropertyType[]) => {
    setHotelResortFilterData(val);
  };

  const setResidentFilter = (val: PropertyType[]) => {
    setResidentFilterData(val);
  };

  const setApartmentFilter = (val: PropertyType[]) => {
    setApartamentFilterData(val);
  };
  const setOtherFilter = (val: PropertyType[]) => {
    setResortOtherFilterData(val);
  };

  const setIjfWeeksActive = (flag: boolean) => {
    setIjfWeeksActiveButton(flag);
  };

  const setOthersHotelResortFilter = (val: PropertyType[]) => {
    setOthersHotelResortFilterData(val);
  };

  const setSearch = (search: SearchType) => {
    setSearchInfo(search);
  };

  const value = {
    dates,
    destination_id,
    search_type,
    search_text,
    dest_type,
    latitude,
    longitude,
    paxConfig,
    bookInfo,
    travelers,
    travBookUniqueId,
    currentRoomsSelecctions,
    currentHolidayStayMinPrice,
    hotelResortFilter,
    scrollWheel,
    residentFilter,
    apartamentFilter,
    resortOtherFilter,
    ijfWeeksActive,
    othersHotelResortFilter,
    search,
    setSearch,
    setDate,
    setDates,
    setCurrentHolidayStayMinPrice,
    setBookInfo,
    setTraveler,
    setTravelers,
    setTravBookUniqueId,
    setBookSelection,
    setDestinationId,
    setSearchType,
    setSearchText,
    setDestType,
    setLatitude,
    setLongitude,
    setPaxConfig,
    setHotelResortFilter,
    setScrollWheel,
    setResidentFilter,
    setApartmentFilter,
    setOtherFilter,
    setIjfWeeksActive,
    setOthersHotelResortFilter,
  };
  HolidayStaysContext.displayName = "HolidayStaysContext";
  return (
    <>
      <HolidayStaysContext.Provider value={value}>
        {children}
      </HolidayStaysContext.Provider>
    </>
  );
}
