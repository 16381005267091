import { addDays } from "date-fns";
import { createContext, ReactNode, useContext, useState } from "react";
import { Moment } from "moment";
import { INIT_CHECKIN, INIT_CHECKOUT } from "@data/consts";

type CarContextType = {
  dates: CarDateType;
  pickLocation: string;
  dropLocation: string;
  pickUpCode: string;
  dropUpCode: string;
  picktime: string;
  droptime: string;
  driverAge?: OptionsType;
  pickUpDate: Date;
  dropUpDate: Date;
  isSameLocation: boolean;
  setDates: (date1: Date | Moment, date2: Date | Moment) => void;
  setDate: (key: string, date: Date | Moment) => void;
  setPickLocation: (val: string) => void;
  setPickUpCode: (val: string) => void;
  setDropUpCode: (val: string) => void;
  setDropLocation: (val: string) => void;
  setPickTime: (val: string) => void;
  setDropTime: (val: string) => void;
  setDriverAge: (val: OptionsType) => void;
  setPickUpDate: (val: Date) => void;
  setDropUpDate: (val: Date) => void;
  setIsSameLocation: (val: boolean) => void;
  pickUpCityName: string;
  setPickUpCityName: (data: string) => void;
  pickUpCountryName: string;
  setPickUpCountryName: (data: string) => void;
  dropCityName: string;
  setDropCityName: (data: string) => void;
  dropCountryName: string;
  setDropCountryName: (data: string) => void;
  countAddedTripsFlag: number;
  setCountAddedTripsFlag: (data: number) => void;
};

const CarContextDefaultValues: CarContextType = {
  dates: {
    from: INIT_CHECKIN,
    to: INIT_CHECKOUT,
  },
  pickLocation: "",
  pickUpCode: "",
  dropUpCode: "",
  dropLocation: "",
  pickUpDate: new Date(),
  dropUpDate: addDays(new Date(), 1),
  picktime: "12:00",
  droptime: "12:00",
  driverAge: undefined,
  isSameLocation: true,
  setDate: () => {},
  setDates: () => {},
  setPickLocation: () => {},
  setPickUpCode: () => {},
  setDropUpCode: () => {},
  setDropLocation: () => {},
  setPickTime: () => {},
  setDropTime: () => {},
  setDriverAge: () => {},
  setPickUpDate: () => {},
  setDropUpDate: () => {},
  setIsSameLocation: () => {},
  pickUpCityName: "",
  setPickUpCityName: () => {},
  pickUpCountryName: "",
  setPickUpCountryName: () => {},
  dropCityName: "",
  setDropCityName: () => {},
  dropCountryName: "",
  setDropCountryName: () => {},
  countAddedTripsFlag: 0,
  setCountAddedTripsFlag: () => {},
};

const CarContext = createContext<CarContextType>(CarContextDefaultValues);

export function useCar() {
  return useContext(CarContext);
}

type Props = {
  children: ReactNode;
};

export function CarProvider({ children }: Props) {
  const [dates, setDateInfo] = useState<FlightDateType>(
    CarContextDefaultValues.dates
  );
  const [pickLocation, setPickLocationState] = useState<string>(
    CarContextDefaultValues.pickLocation
  );
  const [pickUpCode, setPickUpCodeState] = useState<string>(
    CarContextDefaultValues.pickUpCode
  );
  const [dropUpCode, setDropUpCodeState] = useState<string>(
    CarContextDefaultValues.dropUpCode
  );
  const [dropLocation, setDropLocationState] = useState<string>(
    CarContextDefaultValues.dropLocation
  );
  const [picktime, setPicktimeState] = useState<string>(
    CarContextDefaultValues.picktime
  );
  const [droptime, setDroptimeState] = useState<string>(
    CarContextDefaultValues.droptime
  );
  const [driverAge, setDriverAgeState] = useState<OptionsType | undefined>(
    CarContextDefaultValues.driverAge
  );
  const [pickUpDate, setPickUpDateState] = useState<Date>(
    CarContextDefaultValues.pickUpDate
  );
  const [dropUpDate, setDropUpDateState] = useState<Date>(
    CarContextDefaultValues.dropUpDate
  );
  const [isSameLocation, setIsSameLocationState] = useState<boolean>(
    CarContextDefaultValues.isSameLocation
  );

  const [pickUpCityName, setPickUpCityName] = useState<string>(
    CarContextDefaultValues.pickUpCityName
  );
  const [pickUpCountryName, setPickUpCountryName] = useState<string>(
    CarContextDefaultValues.pickUpCountryName
  );
  const [dropCityName, setDropCityName] = useState<string>(
    CarContextDefaultValues.dropCityName
  );
  const [dropCountryName, setDropCountryName] = useState<string>(
    CarContextDefaultValues.dropCountryName
  );

  const [countAddedTripsFlag, setCountAddedTripsFlag] = useState<number>(
    CarContextDefaultValues.countAddedTripsFlag
  );

  const setDate = (key: string, date: Date | Moment) => {
    setDateInfo({
      ...dates,
      [key === "from" ? "from" : "to"]: date,
    });
  };

  const setDates = (date1: Date | Moment, date2: Date | Moment) => {
    setDateInfo({
      from: date1,
      to: date2,
    });
  };

  const setPickLocation = (val: string) => {
    setPickLocationState(val);
  };

  const setPickUpCode = (val: string) => {
    setPickUpCodeState(val);
  };

  const setDropUpCode = (val: string) => {
    setDropUpCodeState(val);
  };

  const setDropLocation = (val: string) => {
    setDropLocationState(val);
  };

  const setPickTime = (val: string) => {
    setPicktimeState(val);
  };

  const setDropTime = (val: string) => {
    setDroptimeState(val);
  };

  const setDriverAge = (val: OptionsType) => {
    setDriverAgeState(val);
  };

  const setPickUpDate = (val: Date) => {
    setPickUpDateState(val);
  };

  const setDropUpDate = (val: Date) => {
    setDropUpDateState(val);
  };
  const setIsSameLocation = (val: boolean) => {
    setIsSameLocationState(val);
  };

  const value = {
    dates,
    pickLocation,
    dropLocation,
    pickUpCode,
    dropUpCode,
    picktime,
    droptime,
    driverAge,
    pickUpDate,
    dropUpDate,
    isSameLocation,
    setDate,
    setDates,
    setPickLocation,
    setDropLocation,
    setPickUpCode,
    setDropUpCode,
    setPickTime,
    setDropTime,
    setDriverAge,
    setPickUpDate,
    setDropUpDate,
    setIsSameLocation,
    pickUpCityName,
    setPickUpCityName,
    pickUpCountryName,
    setPickUpCountryName,
    dropCityName,
    setDropCityName,
    dropCountryName,
    setDropCountryName,
    countAddedTripsFlag,
    setCountAddedTripsFlag,
  };

  CarContext.displayName = "CarContext";
  return <CarContext.Provider value={value}>{children}</CarContext.Provider>;
}
