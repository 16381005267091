import "../styles/globals.css";
import "../styles/tailwind.css";
import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.min.css";
import { createContext, useState } from "react";
import { MainProvider } from "@contexts/mainContext";
import { HotelProvider } from "@contexts/hotelContext";
import { ThemeProvider } from "styled-components";
import { light } from "@themes/light";
import { base } from "@themes/base";
import { LanguageProvider } from "@contexts/languageContext";
import { AuthProvider } from "@contexts/AuthContext";
import { UserProfileProvider } from "@contexts/UserContext";
// import { AdsProvider } from "@contexts/adsContext";
import { FlightProvider } from "@contexts/FlightContext";
import { CarProvider } from "@contexts/CarContext";
import { CarSDProvider } from "@contexts/CarSearchDetailContext";
import { CarRequestFilterProvider } from "@contexts/CarRequestFilterContext";
import { HolidayStayProvider } from "@contexts/HolidayStaysContext";
import { CarCheckoutProvider } from "@contexts/CarCheckoutContext";
import { HolidayStaysSearchDetailProvider } from "@contexts/HolidayStaysSearchDetail";
import { AgencyBookingFilterProvider } from "@contexts/AgentBookingFilterContext";
import { WeekProvider } from "@contexts/WeekContext";
import { ActivityProvider } from "@contexts/ActivityContext";
import { TransfersProvider } from "@contexts/TransfersContext";
import { TransferSearchDetailProvider } from "@contexts/TransferSearchDetail";
import { TransferCheckoutProvider } from "@contexts/TransfersCheckoutContext";
import { ActivityFiltersProvider } from "@contexts/ActivityFilters";
import { LoyaltyProgramProvider } from "@contexts/LoyaltyProgramContext";

const themesMap: any = {
  light,
};
//  setupAxios(axios)

export const ThemePreferenceContext = createContext({});

function MyApp({
  Component,
  pageProps,
}: AppProps & { pageProps: { tailwindEnabled: boolean } }) {
  const [currentTheme, setCurrentTheme] = useState("light");
  const theme = { ...base, colors: { ...light, ...themesMap[currentTheme] } };

  return (
    <ThemePreferenceContext.Provider value={{ currentTheme, setCurrentTheme }}>
      <ThemeProvider theme={theme}>
        <MainProvider>
          <LanguageProvider>
            <HotelProvider>
              <FlightProvider>
                <WeekProvider>
                  <CarProvider>
                    <AgencyBookingFilterProvider>
                      <CarSDProvider>
                        <CarRequestFilterProvider>
                          <CarCheckoutProvider>
                            <HolidayStayProvider>
                              <HolidayStaysSearchDetailProvider>
                                <ActivityProvider>
                                  <TransfersProvider>
                                    <TransferSearchDetailProvider>
                                      <TransferCheckoutProvider>
                                        <AuthProvider>
                                          <UserProfileProvider>
                                            <ActivityFiltersProvider>
                                              <LoyaltyProgramProvider>
                                                {/* <AuthInit> */}
                                                <Component {...pageProps} />
                                                {/* </AuthInit> */}
                                              </LoyaltyProgramProvider>
                                            </ActivityFiltersProvider>
                                          </UserProfileProvider>
                                        </AuthProvider>
                                      </TransferCheckoutProvider>
                                    </TransferSearchDetailProvider>
                                  </TransfersProvider>
                                </ActivityProvider>
                              </HolidayStaysSearchDetailProvider>
                            </HolidayStayProvider>
                          </CarCheckoutProvider>
                        </CarRequestFilterProvider>
                      </CarSDProvider>
                    </AgencyBookingFilterProvider>
                  </CarProvider>
                </WeekProvider>
              </FlightProvider>
            </HotelProvider>
          </LanguageProvider>
        </MainProvider>
      </ThemeProvider>
    </ThemePreferenceContext.Provider>
  );
}

export default MyApp;
