export const refundableStatus = [
  { label: "All", value: "All", isChecked: true, id: 0 },
  { label: "Refundable", value: "Refundable", isChecked: true, id: 1 },
  { label: "Non-Refundable", value: "Non-Refundable", isChecked: true, id: 2 },
];

export const BookedStatus = [
  { label: "Booked", value: "Booked", checkflag: true, number: 0 },
  { label: "Cancelled", value: "Cancelled", checkflag: true, number: 0 },
  { label: "Pending", value: "Pending", checkflag: true, number: 0 },
  // { label: 'Cancellation Pending', value: 'Cancellation Pending', checkflag: true, number: '(345)' },
  { label: "Abondoned", value: "Abondoned", checkflag: true, number: 0 },
  { label: "Failed", value: "Failed", checkflag: true, number: 0 },
  { label: "Inititated", value: "Inititated", checkflag: true, number: 0 },
];

export const sortByOption = [
  { value: "All", label: "All Bookings" },
  { value: "Upcoming", label: "Upcoming Bookings" },
  { value: "Past", label: "Past Bookings" },
];
