import { createContext, ReactNode, useContext, useState } from "react";

type CarSearchDetailContextType = {
  data: CarResponseDataType | null;
  carsData: CarResponseDataCarsType[];
  currentLat: number;
  currentLng: number;
  uid: string;
  carIdSelected: string;
  carPriceSelected: CarResponseDataFareSummaryType | null;
  sortBy: SRPSortBy;
  carsDataPaginated: CarResponseDataCarsType[];
  globalNumCars: number;
  coordinatesCenter: CoordinatesType;
  initDefaultZoom: number;
  showLoadMore: boolean;
  setShowLoadMore: (value: boolean) => void;
  setCoordinatesCenter: (data: CoordinatesType) => void;
  setData: (data: CarResponseDataType) => void;
  setCars: (data: CarResponseDataCarsType[]) => void;
  setCurrentLat: (val: number) => void;
  setCurrentLng: (val: number) => void;
  setUid: (val: string) => void;
  setCarIdSelected: (val: string) => void;
  SetCarPriceSelected: (data: CarResponseDataFareSummaryType) => void;
  setSortByObj: (data: SRPSortBy) => void;
  setCarsDataPaginated: (data: CarResponseDataCarsType[]) => void;
  setCarsData: (response: CarResponseDataCarsType[]) => void;
  setGlobalNumCars: (value: number) => void;
};

const CarSearchDetailDefaultValues: CarSearchDetailContextType = {
  data: null,
  carsData: [],
  carIdSelected: "",
  uid: "",
  currentLat: 0,
  currentLng: 0,
  carPriceSelected: null,
  sortBy: {
    id: 1,
    name: "PRICE LOW TO HIGH",
    order: "asc",
    key: "price",
  },
  carsDataPaginated: [],
  globalNumCars: 0,
  coordinatesCenter: {
    lat: 0,
    lng: 0,
  },
  initDefaultZoom: 11.5,
  showLoadMore: true,
  setShowLoadMore: () => {},
  setCoordinatesCenter: () => {},
  setData: () => {},
  setCars: () => {},
  setCurrentLat: () => {},
  setCurrentLng: () => {},
  setUid: () => {},
  setCarIdSelected: () => {},
  SetCarPriceSelected: () => {},
  setSortByObj: () => {},
  setCarsDataPaginated: () => {},
  setCarsData: () => {},
  setGlobalNumCars: () => {},
};

const CarSearchDetailContext = createContext<CarSearchDetailContextType>(
  CarSearchDetailDefaultValues
);

export function useCarSearchDetail() {
  return useContext(CarSearchDetailContext);
}

type Props = {
  children: ReactNode;
};

export function CarSDProvider({ children }: Props) {
  // states
  const [currentLat, setLatitudeInfo] = useState<number>(
    CarSearchDetailDefaultValues.currentLat
  );
  const [currentLng, setLongitudeInfo] = useState<number>(
    CarSearchDetailDefaultValues.currentLng
  );
  const [data, setMainData] = useState<CarResponseDataType | null>(
    CarSearchDetailDefaultValues.data
  );
  const [carsData, setCarsMainData] = useState<CarResponseDataCarsType[]>(
    CarSearchDetailDefaultValues.carsData
  );

  const [sortBy, setSortByOption] = useState<SRPSortBy>(
    CarSearchDetailDefaultValues.sortBy
  );
  const [uid, setUidData] = useState<string>(CarSearchDetailDefaultValues.uid);
  const [carIdSelected, setCarIdSelectedData] = useState<string>(
    CarSearchDetailDefaultValues.carIdSelected
  );
  const [carPriceSelected, setPriceDataInfoSelected] =
    useState<CarResponseDataFareSummaryType | null>(null);

  const [carsDataPaginated, setCarsDataPag] = useState<
    CarResponseDataCarsType[]
  >([]);

  const [globalNumCars, setGlobalNumCarsValue] = useState<number>(
    CarSearchDetailDefaultValues.globalNumCars
  );
  const [coordinatesCenter, setCoordinatesCenterValues] =
    useState<CoordinatesType>(CarSearchDetailDefaultValues.coordinatesCenter);

  const initDefaultZoom = CarSearchDetailDefaultValues.initDefaultZoom;

  const [showLoadMore, setShowLoadMoreData] = useState(
    CarSearchDetailDefaultValues.showLoadMore
  );

  // setter  functions
  const SetCarPriceSelected = (response: CarResponseDataFareSummaryType) => {
    setPriceDataInfoSelected(response);
  };

  const setData = (response: CarResponseDataType) => {
    setMainData(response);
  };
  const setCarsData = (response: CarResponseDataCarsType[]) => {
    setCarsMainData(response);
  };

  const setCarsDataPaginated = (response: CarResponseDataCarsType[]) => {
    setCarsDataPag(response);
  };

  const setGlobalNumCars = (value: number) => {
    setGlobalNumCarsValue(value);
  };

  const setCars = (response: CarResponseDataCarsType[]) => {
    setCarsMainData(response);
  };

  const setCurrentLat = (val: number) => {
    setLatitudeInfo(val);
  };
  const setCurrentLng = (val: number) => {
    setLongitudeInfo(val);
  };

  const setUid = (val: string) => {
    setUidData(val);
  };

  const setCarIdSelected = (val: string) => {
    setCarIdSelectedData(val);
  };

  const setSortByObj = (response: SRPSortBy) => {
    setSortByOption(response);
  };

  const setCoordinatesCenter = (coordinate: CoordinatesType) => {
    setCoordinatesCenterValues(coordinate);
  };

  const setShowLoadMore = (value: boolean) => {
    setShowLoadMoreData(value);
  };

  const value = {
    carIdSelected,
    uid,
    currentLat,
    currentLng,
    data,
    carsData,
    carPriceSelected,
    sortBy,
    initDefaultZoom,
    carsDataPaginated,
    globalNumCars,
    coordinatesCenter,
    showLoadMore,
    setShowLoadMore,
    setData,
    setCars,
    setCurrentLat,
    setCurrentLng,
    setUid,
    setCarIdSelected,
    SetCarPriceSelected,
    setSortByObj,
    setCarsData,
    setCarsDataPaginated,
    setGlobalNumCars,
    setCoordinatesCenter,
  };

  CarSearchDetailContext.displayName = "CarSearchDetailContext";

  return (
    <CarSearchDetailContext.Provider value={value}>
      {children}
    </CarSearchDetailContext.Provider>
  );
}
