import { BookedStatus } from "@data/selects/bookingStaus";
import { addDays } from "date-fns";
import { createContext, ReactNode, useContext, useState } from "react";

type OptionsDropDownCheckType = {
  value: string;
  label: string;
  checkflag: boolean;
  number?: number;
};

type OptionsStatusBookedType = {
  booked: number;
  cancelled: number;
  pending: number;
  abondoned: number;
  failed: number;
  inititated: number;
};

type AgentBookingFilterContextType = {
  dateRangestartDate: Date;
  dateRangesendDate: Date;
  checkInDateBooking: Date;
  checkOutDate: Date;
  optionRefundable: string;
  bookedStatusFilter: OptionsDropDownCheckType[];
  searchGuesName: string;
  searchHotelName: string;
  searchCity: string;
  banDateRes: boolean;
  banDateCheckInOut: boolean;
  countBooked: OptionsStatusBookedType;
  setDateRangesstartDate: (val: Date) => void;
  setDateRangesendDate: (val: Date) => void;
  setCheckInDate: (val: Date) => void;
  setCheckOutDate: (val: Date) => void;
  setOptionRefundable: (optionSelected: string) => void;
  setBookedStatusFilter: (options: OptionsDropDownCheckType[]) => void;
  setSearchGuesName: (val: string) => void;
  setSearchHotelName: (val: string) => void;
  setSearchCity: (val: string) => void;
  setBanDateRes: (val: boolean) => void;
  setBanDateCheckInOut: (val: boolean) => void;
  setCountBooked: (val: OptionsStatusBookedType) => void;
};

const AgentBookingFilterDefaultValues: AgentBookingFilterContextType = {
  dateRangestartDate: new Date(),
  dateRangesendDate: addDays(new Date(), 1),
  checkInDateBooking: new Date(),
  checkOutDate: addDays(new Date(), 1),
  optionRefundable: "All",
  bookedStatusFilter: [],
  searchGuesName: "",
  searchHotelName: "",
  searchCity: "",
  banDateRes: false,
  banDateCheckInOut: false,
  countBooked: {
    booked: 0,
    cancelled: 0,
    pending: 0,
    abondoned: 0,
    failed: 0,
    inititated: 0,
  },
  setDateRangesstartDate: () => {},
  setDateRangesendDate: () => {},
  setCheckInDate: () => {},
  setCheckOutDate: () => {},
  setOptionRefundable: () => {},
  setBookedStatusFilter: () => {},
  setSearchGuesName: () => {},
  setSearchHotelName: () => {},
  setSearchCity: () => {},
  setBanDateRes: () => {},
  setBanDateCheckInOut: () => {},
  setCountBooked: () => {},
};

const AgencyBookingFilterContext = createContext<AgentBookingFilterContextType>(
  AgentBookingFilterDefaultValues
);

export function useAgentBookingFilter() {
  return useContext(AgencyBookingFilterContext);
}

type Props = {
  children: ReactNode;
};

export function AgencyBookingFilterProvider({ children }: Props) {
  const [dateRangestartDate, setDateRangestartData] = useState<Date>(
    AgentBookingFilterDefaultValues.dateRangestartDate
  );

  const [dateRangesendDate, setDateRangesendData] = useState<Date>(
    AgentBookingFilterDefaultValues.dateRangesendDate
  );

  const [checkInDateBooking, setCheckIntData] = useState<Date>(
    AgentBookingFilterDefaultValues.checkInDateBooking
  );

  const [checkOutDate, setCheckOutData] = useState<Date>(
    AgentBookingFilterDefaultValues.checkOutDate
  );

  const [optionRefundable, setOptionData] = useState<string>(
    AgentBookingFilterDefaultValues.optionRefundable
  );

  const [searchGuesName, setSearchGuesNames] = useState<string>(
    AgentBookingFilterDefaultValues.searchGuesName
  );

  const [searchHotelName, setSearchHotelNames] = useState<string>(
    AgentBookingFilterDefaultValues.searchHotelName
  );

  const [searchCity, setSearchCitys] = useState<string>(
    AgentBookingFilterDefaultValues.searchCity
  );

  const [bookedStatusFilter, bookedStatusFilterData] = useState<
    OptionsDropDownCheckType[]
  >(() => {
    return BookedStatus.map((b) => {
      return { ...b, checkflag: true };
    });
  });

  const [banDateRes, setBanDateResData] = useState<boolean>(
    AgentBookingFilterDefaultValues.banDateRes
  );

  const [banDateCheckInOut, setBanDateCheckInOutData] = useState<boolean>(
    AgentBookingFilterDefaultValues.banDateCheckInOut
  );

  const [countBooked, setCountBookedData] = useState<OptionsStatusBookedType>(
    AgentBookingFilterDefaultValues.countBooked
  );

  const setCountBooked = (val: OptionsStatusBookedType) => {
    setCountBookedData(val);
  };

  const setBanDateRes = (val: boolean) => {
    setBanDateResData(val);
  };

  const setBanDateCheckInOut = (val: boolean) => {
    setBanDateCheckInOutData(val);
  };

  const setDateRangesstartDate = (date: Date) => {
    setDateRangestartData(date);
  };

  const setDateRangesendDate = (date: Date) => {
    setDateRangesendData(date);
  };

  const setCheckInDate = (date: Date) => {
    setCheckIntData(date);
  };

  const setCheckOutDate = (date: Date) => {
    setCheckOutData(date);
  };

  const setOptionRefundable = (optionSelected: string) => {
    setOptionData(optionSelected || "");
  };

  const setSearchGuesName = (val: string) => {
    setSearchGuesNames(val || "");
  };

  const setSearchHotelName = (val: string) => {
    setSearchHotelNames(val || "");
  };

  const setSearchCity = (val: string) => {
    setSearchCitys(val || "");
  };

  const setBookedStatusFilter = (options: OptionsDropDownCheckType[]) => {
    bookedStatusFilterData(options);
  };

  const value = {
    dateRangestartDate,
    setDateRangesstartDate,
    dateRangesendDate,
    setDateRangesendDate,
    checkInDateBooking,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    optionRefundable,
    setOptionRefundable,
    bookedStatusFilter,
    setBookedStatusFilter,
    searchGuesName,
    setSearchGuesName,
    searchHotelName,
    setSearchHotelName,
    searchCity,
    setSearchCity,
    banDateRes,
    setBanDateRes,
    banDateCheckInOut,
    setBanDateCheckInOut,
    countBooked,
    setCountBooked,
  };

  AgencyBookingFilterContext.displayName = "AgentBookingFilterContext";
  return (
    <AgencyBookingFilterContext.Provider value={value}>
      {children}
    </AgencyBookingFilterContext.Provider>
  );
}
