import { createContext, ReactNode, useContext, useState } from "react";

type UserContextType = {
  userProfile: UserProfileType;
  setUserProfile: (data: UserProfileType) => void;
  bookingHistorySection: string;
  setBookingSelected: (section: string) => void;
  creditLimit: number;
  usrRole: string;
  walletcssinfo: boolean;
  setWalletcssinfo: (state: boolean) => void;
  setUsrRole: (section: string) => void;
  setCreditLimit: (val: number) => void;
  setUserProfileImage: (img: string) => void;
  setCompanyLogo: (img: string) => void;
  setUserProfileCurrency: (value: string) => void;
  setUserProfileLang: (value: string) => void;
};

const UserContextDefaultValues: UserContextType = {
  userProfile: {
    basicprofile: [],
    cash: [],
    certificates: [],
    discount: [],
    promotions: [],
    travel: [],
    travelpreference: [],
  },
  setUserProfile: () => {},
  bookingHistorySection: "allBooking",
  setBookingSelected: () => {},
  creditLimit: 0,
  usrRole: "",
  walletcssinfo: false,
  setWalletcssinfo: () => {},
  setUsrRole: () => {},
  setCreditLimit: () => {},
  setUserProfileImage: () => {},
  setCompanyLogo: () => {},
  setUserProfileCurrency: () => {},
  setUserProfileLang: () => {},
};

const UserProfileContext = createContext<UserContextType>(
  UserContextDefaultValues
);

export function useUserProfile() {
  return useContext(UserProfileContext);
}

type Props = {
  children: ReactNode;
};

export function UserProfileProvider({ children }: Props) {
  const [userProfile, setUserProfileInfo] = useState<UserProfileType>(
    UserContextDefaultValues.userProfile
  );
  const [bookingHistorySection, setBookingHistorySection] =
    useState<any>("allBooking");

  const [creditLimit, setCreditLimitData] = useState<number>(
    UserContextDefaultValues.creditLimit
  );

  const [usrRole, setUsrRoleData] = useState<string>(
    UserContextDefaultValues.usrRole
  );
  const [walletcssinfo, setWalletcssmoreinfo] = useState<boolean>(
    UserContextDefaultValues.walletcssinfo
  );

  const setUserProfile = (data: UserProfileType) => {
    setUserProfileInfo({
      ...userProfile,
      ...data,
    });
  };
  const setBookingSelected = (tab: string) => {
    setBookingHistorySection(tab);
  };

  const setCreditLimit = (val: number) => {
    setCreditLimitData(val);
  };

  const setUserProfileImage = (img: string) => {
    const tmp = { ...userProfile };
    tmp.basicprofile[0].profileImage = img;
    setTimeout(function () {
      setUserProfile(tmp);
    }, 1000);
  };

  const setCompanyLogo = (img: string) => {
    const tmp = { ...userProfile };
    tmp.basicprofile[0].usr_org_logo = img;
    setTimeout(function () {
      setUserProfile(tmp);
    }, 1000);
  };

  const setUserProfileCurrency = (value: string) => {
    setUserProfile: ({ ...userProfile, currency: value });
  };

  const setUserProfileLang = (value: string) => {
    setUserProfile: ({ ...userProfile, language: value });
  };

  const setUsrRole = (value: string) => {
    setUsrRoleData(value);
  };
  const setWalletcssinfo = (value: boolean) => {
    setWalletcssmoreinfo(value);
  };

  const value = {
    userProfile,
    setUserProfile,
    bookingHistorySection,
    setBookingSelected,
    creditLimit,
    usrRole,
    walletcssinfo,
    setWalletcssinfo,
    setUsrRole,
    setCreditLimit,
    setUserProfileImage,
    setCompanyLogo,
    setUserProfileCurrency,
    setUserProfileLang,
  };

  UserProfileContext.displayName = "UserPofileContex";
  return (
    <>
      <UserProfileContext.Provider value={value}>
        {children}
      </UserProfileContext.Provider>
    </>
  );
}
