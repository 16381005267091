import { createContext, ReactNode, useContext, useState } from "react";

type CarRequestFilterContextType = {
  filterRequest: SearchFilterRequestData;
  mainFilterSelected: string;
  capacityFilter: CarResponseDataFacetCountsPaxBagRangeType | null;
  baggaeCheckbox: CarFilterCheckbox[];
  passengerCheckbox: CarFilterCheckbox[];
  pickupCheckbox: CarFilterCheckbox[];
  dropCheckbox: CarFilterCheckbox[];
  carTypeCheckbox: CarFilterCheckbox[];
  totalAmtList: (string | number)[];
  maxPrice: number;
  minPrice: number;
  companyCheckbox: CarFilterCheckbox[];
  sizeList: CarResponseDataFacetCountsFacetFieldsSizeArr[];
  paymentOptionList: CarFilterCheckbox[];
  policiesList: CarFilterCheckbox[];
  specificationsList: CarFilterCheckbox[];
  morefiltersApplied: number;
  setMorefiltersApplied: (value: number) => void;
  setPaymentList: (data: CarFilterCheckbox[]) => void;
  setPoliciesList: (data: CarFilterCheckbox[]) => void;
  setSpecificationsList: (data: CarFilterCheckbox[]) => void;
  setSizeList: (value: CarResponseDataFacetCountsFacetFieldsSizeArr[]) => void;
  setCompanyCheckbox: (value: CarFilterCheckbox[]) => void;
  setFilterRequest: (request: SearchFilterRequestData) => void;
  setMainFilterSelected: (value: string) => void;
  setCapacityfilter: (value: CarResponseDataFacetCountsPaxBagRangeType) => void;
  setBaggaeCheckbox: (value: CarFilterCheckbox[]) => void;
  setPassengerCheckbox: (value: CarFilterCheckbox[]) => void;
  setPickupCheckbox: (value: CarFilterCheckbox[]) => void;
  setDropoffCheckbox: (value: CarFilterCheckbox[]) => void;
  setCarTypeCheckbox: (value: CarFilterCheckbox[]) => void;
  setTotalAmnList: (value: (string | number)[]) => void;
  setMinRangePrice: (value: number) => void;
  setMaxRangePrice: (value: number) => void;
  setBags: (value: number[]) => void;
  setCarType: (value: string[]) => void;
  setCurrency: (value: string) => void;
  setDropByLocation: (value: string) => void;
  seLimit: (value: number) => void;
  setMaxPrice: (value: number) => void;
  setMinPrice: (value: number) => void;
  setRangePrice: (min: number, max: number) => void;
  setOffset: (value: number) => void;
  setPassengers: (value: number[]) => void;
  setPickupByLocation: (value: string) => void;
  setRefundable: (value: string) => void;
  setSortBy: (value: string) => void;
  setSortOn: (value: string) => void;
  setSort: (key: string, order: string) => void;
  setUid: (value: string) => void;
  setCompname: (value: string[]) => void;
  setSize: (value: string) => void;
};

const CarRequestFilterDefaultValues: CarRequestFilterContextType = {
  filterRequest: {
    bags: [],
    car_type: [],
    currency: "USD",
    dropbylocation: "",
    limit: 80,
    max_price: "",
    min_price: "",
    offset: 0,
    passengers: [],
    pickupbylocation: "",
    refundable: "",
    sort_by: "asc",
    sort_on: "price",
    uid: undefined,
    compname: [],
    size: "",
  },
  mainFilterSelected: "",
  capacityFilter: null,
  baggaeCheckbox: [],
  passengerCheckbox: [],
  pickupCheckbox: [],
  dropCheckbox: [],
  carTypeCheckbox: [],
  totalAmtList: [],
  maxPrice: 0,
  minPrice: 0,
  companyCheckbox: [],
  sizeList: [],
  paymentOptionList: [],
  policiesList: [],
  specificationsList: [],
  morefiltersApplied: 0,
  setMorefiltersApplied: () => {},
  setPaymentList: () => {},
  setPoliciesList: () => {},
  setSpecificationsList: () => {},
  setSizeList: () => {},
  setCompanyCheckbox: () => {},
  setTotalAmnList: () => {},
  setFilterRequest: () => {},
  setMainFilterSelected: () => {},
  setCapacityfilter: () => {},
  setBaggaeCheckbox: () => {},
  setPassengerCheckbox: () => {},
  setPickupCheckbox: () => {},
  setDropoffCheckbox: () => {},
  setCarTypeCheckbox: () => {},
  setMinRangePrice: () => {},
  setMaxRangePrice: () => {},
  setBags: () => {},
  setCarType: () => {},
  setCurrency: () => {},
  setDropByLocation: () => {},
  seLimit: () => {},
  setMaxPrice: () => {},
  setMinPrice: () => {},
  setRangePrice: () => {},
  setOffset: () => {},
  setPassengers: () => {},
  setPickupByLocation: () => {},
  setUid: () => {},
  setRefundable: () => {},
  setSortBy: () => {},
  setSortOn: () => {},
  setSort: () => {},
  setCompname: () => {},
  setSize: () => {},
};

const CarRequestFilterContext = createContext<CarRequestFilterContextType>(
  CarRequestFilterDefaultValues
);

export function useRequestFilter() {
  return useContext(CarRequestFilterContext);
}

type Props = {
  children: ReactNode;
};

export function CarRequestFilterProvider({ children }: Props) {
  // states

  const [filterRequest, setFilterRequestData] =
    useState<SearchFilterRequestData>(
      CarRequestFilterDefaultValues.filterRequest
    );

  const [mainFilterSelected, setMainFilter] = useState<string>(
    CarRequestFilterDefaultValues.mainFilterSelected
  );

  const [capacityFilter, setCapacityData] =
    useState<CarResponseDataFacetCountsPaxBagRangeType | null>(
      CarRequestFilterDefaultValues.capacityFilter
    );

  const [baggaeCheckbox, setBaggae] = useState<CarFilterCheckbox[]>([]);
  const [passengerCheckbox, setPassenger] = useState<CarFilterCheckbox[]>([]);
  const [pickupCheckbox, setPickup] = useState<CarFilterCheckbox[]>([]);
  const [dropCheckbox, setDropoff] = useState<CarFilterCheckbox[]>([]);
  const [carTypeCheckbox, setCarTypeList] = useState<CarFilterCheckbox[]>([]);
  const [totalAmtList, setTotalAmountList] = useState<(string | number)[]>([]);
  const [maxPrice, setMaximumPrice] = useState<number>(0);
  const [minPrice, setMinimumPrice] = useState<number>(0);
  const [companyCheckbox, setCompanyfilterList] = useState<CarFilterCheckbox[]>(
    []
  );
  const [paymentOptionList, setPaymentOptionList] = useState<
    CarFilterCheckbox[]
  >([]);
  const [policiesList, setPoliciesOptionList] = useState<CarFilterCheckbox[]>(
    []
  );
  const [specificationsList, setSpecificationsOptionList] = useState<
    CarFilterCheckbox[]
  >([]);

  const [sizeList, setSizeAllList] = useState<
    CarResponseDataFacetCountsFacetFieldsSizeArr[]
  >([]);
  const [morefiltersApplied, setMorefiltersAppliedValue] = useState<number>(0);
  // end states

  //functions
  const setSizeList = (
    data: CarResponseDataFacetCountsFacetFieldsSizeArr[]
  ) => {
    setSizeAllList(data);
  };

  const setFilterRequest = (data: SearchFilterRequestData) => {
    setFilterRequestData(data);
  };

  const setMainFilterSelected = (option: string) => {
    setMainFilter(option);
  };
  const setBaggaeCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setBaggae(checkbox);
  };

  const setPassengerCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setPassenger(checkbox);
  };
  const setPickupCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setPickup(checkbox);
  };

  const setDropoffCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setDropoff(checkbox);
  };

  const setCarTypeCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setCarTypeList(checkbox);
  };

  const setTotalAmnList = (List: (string | number)[]) => {
    setTotalAmountList(List);
  };

  const setMinRangePrice = (value: number) => {
    setMinimumPrice(value);
  };
  const setMaxRangePrice = (value: number) => {
    setMaximumPrice(value);
  };

  const setCompanyCheckbox = (checkbox: CarFilterCheckbox[]) => {
    setCompanyfilterList(checkbox);
  };

  const setCapacityfilter = (
    data: CarResponseDataFacetCountsPaxBagRangeType
  ) => {
    setCapacityData(data);
  };

  const setPaymentList = (data: CarFilterCheckbox[]) => {
    setPaymentOptionList(data);
  };

  const setPoliciesList = (data: CarFilterCheckbox[]) => {
    setPoliciesOptionList(data);
  };

  const setSpecificationsList = (data: CarFilterCheckbox[]) => {
    setSpecificationsOptionList(data);
  };

  const setBags = (value: number[]) => {
    setFilterRequestData({
      ...filterRequest,
      bags: value,
    });
  };

  const setCarType = (value: string[]) => {
    setFilterRequestData({
      ...filterRequest,
      car_type: value,
    });
  };

  const setCompname = (value: string[]) => {
    setFilterRequestData({
      ...filterRequest,
      compname: value,
    });
  };

  const setSize = (size: string) => {
    setFilterRequestData({
      ...filterRequest,
      size: size,
    });
  };

  const setCurrency = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      currency: value,
    });
  };

  const setDropByLocation = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      dropbylocation: value,
    });
  };

  const seLimit = (value: number) => {
    setFilterRequestData({
      ...filterRequest,
      limit: value,
    });
  };

  const setMaxPrice = (value: number) => {
    setFilterRequestData({
      ...filterRequest,
      max_price: value,
    });
  };

  const setMinPrice = (value: number) => {
    setFilterRequestData({
      ...filterRequest,
      min_price: value,
    });
  };

  const setRangePrice = (min: number, max: number) => {
    setFilterRequestData({
      ...filterRequest,
      min_price: min,
      max_price: max,
    });
  };

  const setOffset = (value: number) => {
    setFilterRequestData({
      ...filterRequest,
      offset: value,
    });
  };

  const setPassengers = (value: number[]) => {
    setFilterRequestData({
      ...filterRequest,
      passengers: value,
    });
  };

  const setPickupByLocation = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      pickupbylocation: value,
    });
  };

  const setRefundable = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      refundable: value,
    });
  };

  const setSortBy = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      sort_by: value,
    });
  };
  const setSortOn = (value: string) => {
    setFilterRequestData({
      ...filterRequest,
      sort_on: value,
    });
  };

  const setSort = (key: string, order: string) => {
    setFilterRequestData({
      ...filterRequest,
      sort_on: key,
      sort_by: order,
    });
  };

  const setUid = (value: string | undefined) => {
    setFilterRequestData({
      ...filterRequest,
      uid: value,
    });
  };

  const setMorefiltersApplied = (value: number) => {
    setMorefiltersAppliedValue(value);
  };

  const value = {
    filterRequest,
    mainFilterSelected,
    capacityFilter,
    baggaeCheckbox,
    passengerCheckbox,
    pickupCheckbox,
    dropCheckbox,
    totalAmtList,
    maxPrice,
    minPrice,
    companyCheckbox,
    sizeList,
    carTypeCheckbox,
    paymentOptionList,
    policiesList,
    specificationsList,
    morefiltersApplied,
    setMorefiltersApplied,
    setPaymentList,
    setPoliciesList,
    setSpecificationsList,
    setSizeList,
    setCompanyCheckbox,
    setMinRangePrice,
    setMaxRangePrice,
    setTotalAmnList,
    setPickupCheckbox,
    setDropoffCheckbox,
    setBaggaeCheckbox,
    setPassengerCheckbox,
    setCarTypeCheckbox,
    setFilterRequest,
    setMainFilterSelected,
    setCapacityfilter,
    setBags,
    setCarType,
    setCurrency,
    setDropByLocation,
    seLimit,
    setMaxPrice,
    setMinPrice,
    setRangePrice,
    setOffset,
    setPassengers,
    setPickupByLocation,
    setRefundable,
    setSortBy,
    setSortOn,
    setSort,
    setUid,
    setCompname,
    setSize,
  };

  CarRequestFilterContext.displayName = "CarRequestFilterContext";

  return (
    <CarRequestFilterContext.Provider value={value}>
      {children}
    </CarRequestFilterContext.Provider>
  );
}
