import { onlyUnique } from "@helpers/arrayFuntions";
import {
  ACTIVITY_LOCAL_STORAGE_START_TIME,
  getActGeneralArray,
  getMinMaxPrice,
  setActGeneral,
  setMinPrice as setMinPriceStorage,
} from "@helpers/helperActivities";
import { createContext, ReactNode, useContext, useState } from "react";
type Props = {
  children: ReactNode;
};
type FilterRequestBackendType = {
  startPrice?: number;
  endPrice?: number;
  startScore?: number;
  endScore?: number;
};
type ActivityContextType = {
  filterRequestBackend: FilterRequestBackendType | null;
  minPrice: number;
  maxPrice: number;
  setMinPrice: (val: number) => void;
  setMaxPrice: (val: number) => void;
  setMinPriceArray: (val: number) => void;
  tagIDMenuSelected: number[];
  setTagIDMenuSelected: (val: number) => void;
  specialsCheckbox: ActivityFilterCheckbox[];
  setSpecialsCheckbox: (value: ActivityFilterCheckbox[]) => void;
  activityTypeCheckbox: ActivityFilterCheckbox[];
  setActivityTypeCheckbox: (value: ActivityFilterCheckbox[]) => void;
  timeOfDayCheckbox: ActivityFilterCheckbox[];
  setTimeOfDayCheckbox: (value: ActivityFilterCheckbox[]) => void;
  durationActivityCheckbox: ActivityFilterCheckbox[];
  setDurationActivityCheckbox: (value: ActivityFilterCheckbox[]) => void;
  ratingSelected: number[];
  setRatingSelected: (val: number[]) => void;
  setMinPriceReset: () => void;
  setDurationActivityInit: (val: number) => void;
  setStartTimeActivityInit: (val: string) => void;
  resetAllFilters: () => void;
  setFilterRequestBackend: (val: FilterRequestBackendType | null) => void;
  setFilterRequestBackendApply: () => void;
};

const ActivityContextDefaultValues: ActivityContextType = {
  //Initial
  filterRequestBackend: null,
  minPrice: 0,
  maxPrice: 0,
  setMinPrice: () => {},
  setMaxPrice: () => {},
  setMinPriceArray: () => {},
  tagIDMenuSelected: [],
  setTagIDMenuSelected: () => {},
  specialsCheckbox: [],
  setSpecialsCheckbox: () => {},
  activityTypeCheckbox: [],
  setActivityTypeCheckbox: () => {},
  timeOfDayCheckbox: [],
  setTimeOfDayCheckbox: () => {},
  durationActivityCheckbox: [],
  setDurationActivityCheckbox: () => {},
  ratingSelected: [1, 5],
  setRatingSelected: () => {},
  setMinPriceReset: () => {},
  setDurationActivityInit: () => {},
  setStartTimeActivityInit: () => {},
  resetAllFilters: () => {},
  setFilterRequestBackend: () => {},
  setFilterRequestBackendApply: () => {},
};

const DataFiltersActivities = createContext<ActivityContextType>(
  ActivityContextDefaultValues
);

export function useActivityFilters() {
  return useContext(DataFiltersActivities);
}

export function ActivityFiltersProvider({ children }: Props) {
  //States

  const [filterRequestBackend, setFilterRequestBackendData] =
    useState<FilterRequestBackendType | null>(
      ActivityContextDefaultValues.filterRequestBackend
    );

  const [tagIDMenuSelected, setTagIdIdSelectedData] = useState<number[]>(
    ActivityContextDefaultValues.tagIDMenuSelected
  );
  const [specialsCheckbox, setSpecials] = useState<ActivityFilterCheckbox[]>(
    []
  );
  const [activityTypeCheckbox, setActivityType] = useState<
    ActivityFilterCheckbox[]
  >([]);
  const [timeOfDayCheckbox, setTimeOfDay] = useState<ActivityFilterCheckbox[]>(
    []
  );
  const [durationActivityCheckbox, setDurationActivity] = useState<
    ActivityFilterCheckbox[]
  >([]);
  const [ratingSelected, setRatingSelectedData] = useState<number[]>(
    ActivityContextDefaultValues.ratingSelected
  );
  const [minPrice, setMinPriceData] = useState<number>(
    ActivityContextDefaultValues.minPrice
  );
  const [maxPrice, setMaxPriceData] = useState<number>(
    ActivityContextDefaultValues.maxPrice
  );

  //Set functions

  const setTagIDMenuSelected = (val: number) => {
    const aux = [...tagIDMenuSelected];
    const find = tagIDMenuSelected.find((f) => f == val);
    if (find) {
      const aux2 = aux.filter((f) => f != val);
      setTagIdIdSelectedData(aux2);
    } else {
      aux.push(val);
      setTagIdIdSelectedData(aux);
    }
  };
  const setSpecialsCheckbox = (checkbox: ActivityFilterCheckbox[]) => {
    setSpecials(checkbox);
  };
  const setActivityTypeCheckbox = (checkbox: ActivityFilterCheckbox[]) => {
    setActivityType(checkbox);
  };
  const setTimeOfDayCheckbox = (checkbox: ActivityFilterCheckbox[]) => {
    setTimeOfDay(checkbox);
  };
  const setDurationActivityCheckbox = (checkbox: ActivityFilterCheckbox[]) => {
    setDurationActivity(checkbox);
  };
  const setRatingSelected = (val: number[]) => {
    setRatingSelectedData(val);
  };

  const setFilterRequestBackend = (val: FilterRequestBackendType | null) => {
    setFilterRequestBackendData(val);
  };

  const setFilterRequestBackendApply = () => {
    let req = {};
    // if (minPrice > 0 && maxPrice > 0) {
    //   req = { ...req, startPrice: minPrice, endPrice: maxPrice };
    // }

    if (ratingSelected.length == 2) {
      req = {
        ...req,
        startScore: ratingSelected[0],
        endScore: ratingSelected[1],
      };
    }
    setFilterRequestBackendData(req);
  };

  const setDurationActivityInit = (val: number) => {
    //console.log("setDurationActivityInit", val);

    setActGeneral(val);
    const all: any[] = getActGeneralArray();
    let filerData = all.filter(onlyUnique);

    filerData = filerData.map((m) => {
      return { idkey: m, name: `${m}` };
    });

    // setDurationActivityCheckbox(filerData);
  };

  const setStartTimeActivityInit = (val: string) => {
    //console.log("setStartTimeActivityInit", val);
    setActGeneral(val, ACTIVITY_LOCAL_STORAGE_START_TIME);
    const all: any[] = getActGeneralArray(ACTIVITY_LOCAL_STORAGE_START_TIME);
    let filerData = all.filter(onlyUnique);

    filerData = filerData.map((m) => {
      return { idkey: m, name: `${m}` };
    });

    // setTimeOfDayCheckbox(filerData);
  };
  const setMinPriceArray = (val: number) => {
    setMinPriceStorage(val);
    const all = getMinMaxPrice();
    setMinPriceData(all.min);
    setMaxPriceData(all.max);
  };

  const setMinPrice = (val: number) => {
    setMinPriceData(val);
  };
  const setMaxPrice = (val: number) => {
    setMaxPriceData(val);
  };

  const setMinPriceReset = () => {
    const all = getMinMaxPrice();
    setMinPriceData(all.min);
    setMaxPriceData(all.max);
  };

  const resetAllFilters = () => {
    setTagIdIdSelectedData(ActivityContextDefaultValues.tagIDMenuSelected);
    setRatingSelectedData(ActivityContextDefaultValues.ratingSelected);

    setSpecials([]);
    setActivityType([]);
    setTimeOfDay([]);
    setDurationActivity([]);

    const all = getMinMaxPrice();
    setMinPriceData(all.min);
    setMaxPriceData(all.max);

    setFilterRequestBackend(null);
  };

  //Export Functions
  const value = {
    filterRequestBackend,
    tagIDMenuSelected,
    setTagIDMenuSelected,
    specialsCheckbox,
    setSpecialsCheckbox,
    activityTypeCheckbox,
    setActivityTypeCheckbox,
    timeOfDayCheckbox,
    setTimeOfDayCheckbox,
    durationActivityCheckbox,
    setDurationActivityCheckbox,
    ratingSelected,
    setRatingSelected,
    minPrice,
    maxPrice,
    setMinPrice,
    setMaxPrice,
    setMinPriceArray,
    setMinPriceReset,
    setDurationActivityInit,
    setStartTimeActivityInit,
    resetAllFilters,
    setFilterRequestBackend,
    setFilterRequestBackendApply,
  };

  DataFiltersActivities.displayName = "DataFiltersActivities";

  return (
    <DataFiltersActivities.Provider value={value}>
      {children}
    </DataFiltersActivities.Provider>
  );
}
