import { createContext, ReactNode, useContext, useState } from "react";

type mainContextType = {
  currentConfig: ConfigType;
  orgConfig?: OrgBySiteApiType;
  setOrgConfig: (val: OrgBySiteApiType) => void;
  setConfig: (config: any) => void;
  profileTabSelected: string;
  setTabSelected: (tab: string) => void;
  signUpShow: boolean;
  showModalSignIn: boolean;
  setSignUpShow: (val: boolean) => void;
  functSetShowModalSignIn: (val: boolean) => void;
  banners: BannerResponseType | null;
  setBanners: (val: BannerResponseType) => void;
  footer: FooterResponseType | null;
  setFooter: (val: FooterResponseType) => void;
  withTax: boolean | null;
  setWithTax: (val: boolean) => void;
  elegibleProducts: ProductsApiType[];
  setElegibleProducts: (val: ProductsApiType[]) => void;
  favicon: string;
  setFavicon: (val: string) => void;
  siteTitle: string;
  setSiteTitle: (val: string) => void;
  gifLoader: string;
  setGifLoader: (val: string) => void;
  showLoader: boolean;
  setShowLoader: (val: boolean) => void;
  agentProfileTab: string;
  setAgentProfileTab: (val: string) => void;
  /* AGENTS */
  agentName: string;
  setAgentName: (name: string) => void;
  agentSelected: agentsType | null;
  setAgentSelected: ({}: agentsType) => void;
  nationalityName: string;
  setNationalityName: (name: string) => void;
  nationalitySelected: any;
  setNationalitySelected: ({}: nationalityAgentType) => void;
  packageCheked: boolean;
  setPackageChecked: (state: boolean) => void;
  userType: number;
  setUserType: (usrType: number) => void;
  calendarStartDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  setCalendarStartDay: (val: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) => void;
  ip: string;
  setIp: (val: string) => void;
  dateFormat: string;
  setDateFormat: (val: string) => void;
  roomsLabel: number;
  setRoomsLabel: (rooms: number) => void;
  hotelNameApplied: boolean;
  setHotelNameApplied: (flag: boolean) => void;
  nationalityCatalog: NationalityDataType[];
  setNationalityCatalog: (data: NationalityDataType[]) => void;
};

const mainContextDefaultValues: mainContextType = {
  currentConfig: {
    bannerConfig: [],
    footerConfig: [],
    headerConfig: [],
    currencylanguageinfoConfig: [],
    testimonialConfig: [],
  },
  orgConfig: undefined,
  setOrgConfig: () => {},
  banners: null,
  setBanners: () => {},
  footer: null,
  setFooter: () => {},
  setConfig: () => {},
  profileTabSelected: "profile",
  setTabSelected: () => {},
  setSignUpShow: () => {},
  functSetShowModalSignIn: () => {},
  signUpShow: false,
  showModalSignIn: false,
  withTax: null,
  setWithTax: () => {},
  elegibleProducts: [],
  setElegibleProducts: () => {},
  favicon: "/favicon.png",
  setFavicon: () => {},
  siteTitle: "",
  setSiteTitle: () => {},
  gifLoader: "",
  setGifLoader: () => {},
  showLoader: false,
  setShowLoader: () => {},
  agentProfileTab: "profile",
  setAgentProfileTab: () => {},
  agentName: "",
  setAgentName: () => {},
  agentSelected: null,
  setAgentSelected: () => {},
  nationalityName: "",
  setNationalityName: () => {},
  nationalitySelected: null,
  setNationalitySelected: () => {},
  packageCheked: false,
  setPackageChecked: () => {},
  userType: 2, // * 1 = agent, 2 = normal user */
  setUserType: () => {},
  calendarStartDay: 0,
  setCalendarStartDay: () => {},
  ip: "",
  setIp: () => {},
  dateFormat: "DD/MM/YYYY",
  setDateFormat: () => {},
  roomsLabel: 1,
  setRoomsLabel: () => {},
  hotelNameApplied: false,
  setHotelNameApplied: () => {},
  nationalityCatalog: [],
  setNationalityCatalog: () => {},
};

const MainContext = createContext<mainContextType>(mainContextDefaultValues);

export function useMain() {
  return useContext(MainContext);
}

type Props = {
  children: ReactNode;
};

export function MainProvider({ children }: Props) {
  const [orgConfig, setOrgConfigState] = useState<OrgBySiteApiType | undefined>(
    mainContextDefaultValues.orgConfig
  );
  const [currentConfig, setCurrentConfig] = useState<any>([]);
  const [banners, setBannersData] = useState<BannerResponseType | null>(
    mainContextDefaultValues.banners
  );
  const [footer, setFooterData] = useState<FooterResponseType | null>(
    mainContextDefaultValues.footer
  );
  const [signUpShow, setSignUp] = useState(mainContextDefaultValues.signUpShow);
  const [showModalSignIn, setShowModalSignIn] = useState(
    mainContextDefaultValues.showModalSignIn
  );
  const [profileTabSelected, setProfileTabSelected] =
    useState<any>("bookingHistory");

  const [agentProfileTab, setAgentProfileTabData] = useState<string>(
    mainContextDefaultValues.agentProfileTab
  );

  const [withTax, setWithTaxData] = useState(mainContextDefaultValues.withTax);

  const [elegibleProducts, setElegibleProductsData] = useState(
    mainContextDefaultValues.elegibleProducts
  );

  const [favicon, setFaviconData] = useState(mainContextDefaultValues.favicon);

  const [gifLoader, setGifLoaderData] = useState(
    mainContextDefaultValues.gifLoader
  );

  const [showLoader, setShowLoaderData] = useState(
    mainContextDefaultValues.showLoader
  );

  const [siteTitle, setSiteTitleData] = useState(
    mainContextDefaultValues.siteTitle
  );

  const [userType, setUserTypeData] = useState<number>(
    mainContextDefaultValues.userType
  );

  const [calendarStartDay, setCalendarStartDayData] = useState<
    0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  >(mainContextDefaultValues.calendarStartDay);

  const [dateFormat, setDateFormatData] = useState<string>(
    mainContextDefaultValues.dateFormat
  );

  const [ip, setIpData] = useState<string>(mainContextDefaultValues.ip);

  const [roomsLabel, setRoomsLabelInit] = useState(
    mainContextDefaultValues.roomsLabel
  );

  const [hotelNameApplied, setHotelNameAppliedState] = useState(
    mainContextDefaultValues.hotelNameApplied
  );

  const setRoomsLabel = (roomNumber: number) => {
    setRoomsLabelInit(roomNumber);
  };

  const setConfig = (config: any) => {
    setCurrentConfig({ ...currentConfig, ...config });
  };
  const setOrgConfig = (val: OrgBySiteApiType) => {
    setOrgConfigState(val);
  };
  const setTabSelected = (tab: string) => {
    setProfileTabSelected(tab);
  };

  const setSignUpShow = (val: boolean) => {
    setSignUp(val);
  };
  const functSetShowModalSignIn = (val: boolean) => {
    setShowModalSignIn(val);
  };

  const setBanners = (config: BannerResponseType) => {
    setBannersData(config);
  };

  const setFooter = (config: FooterResponseType) => {
    setFooterData(config);
  };

  const setWithTax = (val: boolean) => {
    setWithTaxData(val);
  };

  const setElegibleProducts = (val: ProductsApiType[]) => {
    setElegibleProductsData(val);
  };

  const setFavicon = (val: string) => {
    setFaviconData(val);
  };

  const setSiteTitle = (val: string) => {
    setSiteTitleData(val);
  };

  const setGifLoader = (val: string) => {
    setGifLoaderData(val);
  };

  const setShowLoader = (val: boolean) => {
    setShowLoaderData(val);
  };

  const setAgentProfileTab = (val: string) => {
    setAgentProfileTabData(val);
  };

  const setUserType = (usrType: number) => {
    setUserTypeData(usrType);
  };

  const setCalendarStartDay = (
    usrType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  ) => {
    setCalendarStartDayData(usrType);
  };

  const setIp = (val: string) => {
    setIpData(val);
  };

  const setDateFormat = (val: string) => {
    setDateFormatData(val);
  };

  const setHotelNameApplied = (flag: boolean) => {
    setHotelNameAppliedState(flag);
  };

  /* AGENTS */

  const [agentName, setAgentName] = useState(
    mainContextDefaultValues.agentName
  );

  const [agentSelected, setAgentSelected] = useState(
    mainContextDefaultValues.agentSelected
  );

  const [nationalityName, setNationalityName] = useState(
    mainContextDefaultValues.nationalityName
  );

  const [nationalitySelected, setNationalitySelected] = useState(
    mainContextDefaultValues.nationalitySelected
  );

  const [packageCheked, setPackageChecked] = useState<boolean>(
    mainContextDefaultValues.packageCheked
  );

  const [nationalityCatalog, setNationalityCatalog] = useState<
    NationalityDataType[]
  >(mainContextDefaultValues.nationalityCatalog);

  const value = {
    orgConfig,
    setOrgConfig,
    currentConfig,
    setConfig,
    profileTabSelected,
    setTabSelected,
    signUpShow,
    setSignUpShow,
    banners,
    setBanners,
    footer,
    setFooter,
    functSetShowModalSignIn,
    showModalSignIn,
    withTax,
    setWithTax,
    elegibleProducts,
    setElegibleProducts,
    favicon,
    setFavicon,
    siteTitle,
    setSiteTitle,
    gifLoader,
    setGifLoader,
    showLoader,
    setShowLoader,
    agentProfileTab,
    setAgentProfileTab,
    agentName,
    setAgentName,
    agentSelected,
    setAgentSelected,
    nationalityName,
    setNationalityName,
    nationalitySelected,
    setNationalitySelected,
    packageCheked,
    setPackageChecked,
    userType,
    setUserType,
    calendarStartDay,
    setCalendarStartDay,
    ip,
    setIp,
    dateFormat,
    setDateFormat,
    roomsLabel,
    setRoomsLabel,
    hotelNameApplied,
    setHotelNameApplied,
    nationalityCatalog,
    setNationalityCatalog,
  };

  MainContext.displayName = "MainContext";
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}
