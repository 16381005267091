import axios from "axios";
type AdsCallsRequest = {
  pathName: string;
  orgId: number;
  section: string;
};

export const getAdsBannerRespApi = async (
  data: AdsCallsRequest
): Promise<MainCardType[]> => {
  try {
    const response = await axios.post(`/api/productCards/getBannerCall`, {
      data,
    });

    return response.data;
  } catch (error) {
    console.info(error);
    return [];
  }
};

export const getAdsBannerRespApiBk = async (
  data: AdsCallsRequest,
  domain: string,
  headers: any
): Promise<MainCardType[]> => {
  try {
    const response = await axios.post(
      domain + `/api/productCards/getBannerCall`,
      {
        data,
      },
      {
        headers,
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    return [];
  }
};
