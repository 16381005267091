import { createContext, ReactNode, useContext, useState } from "react";

type languageContextType = {
  langCurrencyData: CurrencylanguageinfoConfigType | null;
  setLangCurrencyData: (value: CurrencylanguageinfoConfigType) => void;
};

const languageContextDefaultValues: languageContextType = {
  langCurrencyData: null,
  setLangCurrencyData: () => {},
};

const LanguageContext = createContext<languageContextType>(
  languageContextDefaultValues
);

export function useLanguage() {
  return useContext(LanguageContext);
}

type Props = {
  children: ReactNode;
};

export function LanguageProvider({ children }: Props) {
  const [langCurrencyData, setLangCurrencyDataState] =
    useState<CurrencylanguageinfoConfigType | null>(
      languageContextDefaultValues.langCurrencyData
    );

  const setLangCurrencyData = (value: CurrencylanguageinfoConfigType) => {
    setLangCurrencyDataState(value);
  };

  const value = {
    langCurrencyData,
    setLangCurrencyData,
  };

  LanguageContext.displayName = "LanguageContext";
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}
