import { NextApiHandler } from "next";
import axios from "axios";
import { logError, logTitle } from "@data/console/colors-log";
import {
  getDomainConfigBackend,
  getDomainConfigBackendByDomain,
} from "@helpers/config";

export const callExternalConfig = async (orgId: string, request: any) => {
  const config = getDomainConfigBackend(request);

  try {
    const url = `${config?.activitiesBussinesPath}/v1/wl/config/${orgId}`;
    const res = await axios.get(url, {});

    return res.data;
  } catch (error: any) {
    logError("getExternalConfig api", error);
    return { error };
  }
};

export const getExternalConfigByDoamin = async (
  orgId: string,
  domain: string
) => {
  const config = getDomainConfigBackendByDomain(domain);
  const url = `${config?.activitiesBussinesPath}/v1/wl/config/${orgId}`;

  try {
    const res = await fetch(url);
    const data = await res.json();

    return data.data;
  } catch (error: any) {
    logError("getExternalConfig api", error);
    return { error };
  }
};

const getExternalConfig: NextApiHandler<any> = async (request, response) => {
  logTitle("getExternalConfig api");
  if (request.method !== "POST") {
    // Not supported method
    response.status(405).end();
  }
  const { orgId } = request.body;

  const data = await callExternalConfig(orgId, request);
  if (!data?.error) {
    return response.status(200).json(data);
  }

  return response.status(301).json({ error: data?.error });
};

export default getExternalConfig;
