import moment from "moment";
import { compareValues } from "./arrayFuntions";
import { isTimeString } from "./helper";
const ACTIVITY_LOCAL_STORAGE_MIN_PRICE = "activity_min_price_array";
const ACTIVITY_LOCAL_STORAGE_DURATION = "activity_duration_array";
const ACTIVITY_LOCAL_STORAGE_START_TIME = "activity_start_time_array";
const ACTIVITY_LOCAL_STORAGE_BOOKED = "activity_booked";
const ACTIVITY_LOCAL_STORAGE_SEARCHBTM = "activity_searchbtm_array";
const REQUIRED_MANDATORY = "MANDATORY";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getDateTravelFormat = (travelDate: string) => {
  if (travelDate !== undefined) {
    const date = new Date(travelDate);
    const nameDay = days[date.getUTCDay()];
    const nameMonth = month[date.getUTCMonth()];
    const numberDay = date.getUTCDate();
    const year = date.getFullYear();
    return `${nameDay}, ${nameMonth} ${numberDay}, ${year}`;
  } else return false;
};

export const getParamsFromQuery = (query: any) => {
  const { destination, destinationText, checkin, checkout } = query;
  return {
    destination: Number(destination || "0"),
    destinationText: String(destinationText || ""),
    checkIn: moment(checkin).toDate(),
    checkOut: moment(checkout).toDate(),
  };
};

export const getParamsFromQueryDetails = (query: any) => {
  const {
    destination,
    productCode,
    destinationText,
    checkin,
    checkout,
    pax,
    productOptionCode,
    startTime,
  } = query;
  return {
    destination: Number(destination || "0"),
    productCode: productCode || "",
    destinationText: String(destinationText || ""),
    checkIn: moment(checkin).toDate(),
    checkOut: moment(checkout).toDate(),
    pax: pax ? JSON.parse(pax) : null,
    productOptionCode: productOptionCode || null,
    startTime: startTime || "",
  };
};

export const getCustomerReviewLabel = (customerRating: number) => {
  if (customerRating <= 5) {
    if (customerRating >= 4) {
      return "EXCELLENT";
    }
    if (customerRating >= 3) {
      return "VERY GOOD";
    }
    return "GOOD";
  } else {
    if (customerRating >= 8.5) {
      return "EXCELLENT";
    }
    if (customerRating >= 6.5) {
      return "VERY GOOD";
    }
    return "GOOD";
  }
};

export const getParamsFromQueryForMenu = (query: any) => {
  const { destination, checkin, checkout } = query;
  return {
    startDate: checkin,
    endDate: checkout,
    destinationsId: [Number(destination || "0")],
    page: 1,
    limit: 100,
    lang: "en",
  };
};

export const getParamsFromQueryForProductDetail = (query: any) => {
  const { destination, checkin, checkout, productCode, productOptionCode, id } =
    query;
  return {
    startDate: checkin,
    endDate: checkout,
    destinationsId: [Number(destination || "0")],
    productCode,
    page: 1,
    limit: 10,
    lang: "en",
    id: id,
    productOptionCode: isTimeString(productOptionCode)
      ? undefined
      : productOptionCode,
  };
};

export const getImage = (images?: ActivityProductImageType[]) => {
  try {
    if (Array.isArray(images)) {
      const image = images[0].variants.sort(compareValues("width", "desc"))[0]
        .url;
      return image;
    }
  } catch (error) {}
  return "/images/general/no_img_fo.jpg";
};

export const getImages = (images?: ActivityProductImageType[]) => {
  try {
    if (Array.isArray(images)) {
      const imagesAux = images.map((i) =>
        i.variants.sort(compareValues("width", "desc"))
      );

      const image = imagesAux.map((m) => m[0]);
      return image;
    }
  } catch (error) {}
  return [{ url: "/images/general/no_img_fo.jpg" }];
};

export const getReviewLabel = (rating: number) => {
  if (rating > 5) {
    if (rating <= 6) {
      return "POOR";
    }
    if (rating <= 7) {
      return "OKEY";
    }
    if (rating <= 8) {
      return "GOOD";
    }
    if (rating <= 9) {
      return "VERY GOOD";
    }
    return "EXCELLENT";
  } else {
    if (rating < 2) {
      return "POOR";
    }
    if (rating < 3) {
      return "OKEY";
    }
    if (rating < 4) {
      return "GOOD";
    }
    if (rating < 4.5) {
      return "VERY GOOD";
    }

    return "EXCELLENT";
  }
};

export const standarRating = (calif: number) => {
  if (calif > 5) {
    return calif.toFixed(1);
  } else {
    return Math.floor(calif * 2).toFixed(1);
  }
};

export const getPaxArray = (
  pax: ActivitiesPaxType | null,
  defaultPax?: ActivityAgeBandsType[]
) => {
  const arrayAux = [];
  for (const key in pax) {
    if (Object.prototype.hasOwnProperty.call(pax, key)) {
      const qty = pax[key];
      /* NOTA - RISHARD:
        SE AGREGA EL  if (qty) {
        POR QUE AVECES NO QUEREMOS SELECCIONAR TODOS LOS TIPOS DE TRAVELERS
        POR EJEMPLO SI LA API NOS TRAE PARA QUE SEAN SENIOR, ADULT, CHILD Y SI SOLAMENTE
        SELECCIONAMOS PUROS ADULTOS... LA API NO TRAE INFORMACION SI LE DICEMOS QUE SON 0 SENIOR Y 0 CHILDS
        Y 3 ADULTS... EN CAMBIO SI SOLO ENVIAMOS QUE SON 3 ADULTS ENTONCES SI TRAE INFORMACION
        POR ESO LA CONSTANTE qty TIENE QUE SER MAYOR A 0 */
      if (qty) {
        arrayAux.push({
          ageBand: key,
          numberOfTravelers: qty,
        });
      }
    }
  }

  if (arrayAux.length == 0) {
    if (defaultPax) {
      for (const origPax of defaultPax) {
        if (origPax.minTravelersPerBooking > 0) {
          arrayAux.push({
            ageBand: origPax.ageBand,
            numberOfTravelers: origPax.minTravelersPerBooking,
          });
        }
      }
    } else {
      return [
        {
          ageBand: "ADULT",
          numberOfTravelers: 1,
        },
      ];
    }
  }

  return arrayAux;
};

const setMinPrice = (val: number) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const values = getMinPriceArray();
    values?.push(val);
    setMinPriceArrayObj(values);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const setMinPriceArrayObj = (val?: number[]) => {
  if (typeof window == "undefined" || !localStorage || !Array.isArray(val)) {
    return;
  }

  try {
    const lsValue = JSON.stringify(val);
    localStorage.setItem(ACTIVITY_LOCAL_STORAGE_MIN_PRICE, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getMinPriceArray = (): number[] => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }

  const lsValue: string | null = localStorage.getItem(
    ACTIVITY_LOCAL_STORAGE_MIN_PRICE
  );
  if (!lsValue) {
    return [0];
  }

  try {
    const numbers: number[] = JSON.parse(lsValue) as number[];
    if (numbers) {
      if (numbers.length == 0) {
        return [0];
      }
      return numbers.sort(function (a, b) {
        return a - b;
      });
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
  return [];
};

const getMinMaxPrice = (): { min: number; max: number } => {
  if (typeof window == "undefined" || !localStorage) {
    return { min: 0, max: 0 };
  }

  const data = getMinPriceArray();
  const length = data?.length;
  if (length && length > 0) {
    if (length > 1) {
      return { min: data[0], max: data[length - 1] };
    }
    return { min: 0, max: data[length - 1] };
  }
  return { min: 0, max: 0 };
};

// duration
const setActGeneral = (
  val: number | string,
  key = ACTIVITY_LOCAL_STORAGE_DURATION
) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const values: any[] = getActGeneralArray(key);
    values?.push(val);
    setGeneralArrayObj(values, key);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getActGeneralArray = (
  key = ACTIVITY_LOCAL_STORAGE_DURATION
): number[] | string[] => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }

  const lsValue: string | null = localStorage.getItem(key);
  if (!lsValue) {
    return [];
  }

  try {
    const numbers: number[] | string[] = JSON.parse(lsValue) as
      | number[]
      | string[];
    if (numbers.length > 0) {
      if (typeof numbers[0] == "string") {
        return numbers.sort();
      }
      return numbers.sort(function (a: any, b: any) {
        return a - b;
      });
    }
    return [];
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
  return [];
};

const setGeneralArrayObj = (
  val?: number[] | string[],
  key = ACTIVITY_LOCAL_STORAGE_DURATION
) => {
  if (typeof window == "undefined" || !localStorage || !Array.isArray(val)) {
    return;
  }

  try {
    const lsValue = JSON.stringify(val);
    localStorage.setItem(key, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const setActBooked = (bookedRef: string, val: any) => {
  const key = ACTIVITY_LOCAL_STORAGE_BOOKED;
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const values = JSON.stringify(val);
    localStorage.setItem(`${key}_${bookedRef}`, values);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getActBooked = (bookedRef: any) => {
  const key = ACTIVITY_LOCAL_STORAGE_BOOKED;
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const values = localStorage.getItem(`${key}_${bookedRef}`);
    const val = JSON.parse(values || "{}");
    return val;
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};
const getSearchBtmActArray = (actstitlesearch?: any) => {
  if (typeof window == "undefined" || !localStorage) {
    return [];
  }

  const lsValue: string | null = localStorage.getItem(
    ACTIVITY_LOCAL_STORAGE_SEARCHBTM
  );
  if (!lsValue) {
    return [];
  }

  try {
    const activititestitlesearch: any = JSON.parse(lsValue);
    const find = activititestitlesearch.find(
      (list: any) => list.productCode == actstitlesearch?.productCode
    );
    if (!find) {
      activititestitlesearch.push({
        productCode: actstitlesearch?.productCode,
        title: actstitlesearch?.title,
        category: actstitlesearch?.category,
        categorytitleCarouselTag: actstitlesearch?.categorytitleCarouselTag,
      });
    }
    return JSON.stringify(activititestitlesearch);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
  return [];
};
const setSearchBtmActivitiesObj = (val?: any) => {
  if (typeof window == "undefined" || !localStorage || !Array.isArray(val)) {
    return;
  }

  try {
    const lsValue = JSON.stringify(val);
    localStorage.setItem(ACTIVITY_LOCAL_STORAGE_SEARCHBTM, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const setSearchBtmActivities = (val?: any) => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }
  try {
    const values: any = getSearchBtmActArray(val);
    localStorage.setItem(ACTIVITY_LOCAL_STORAGE_SEARCHBTM, values);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getSearchBtmActivities = () => {
  if (typeof window == "undefined" || !localStorage) {
    return;
  }

  try {
    const values = localStorage.getItem(ACTIVITY_LOCAL_STORAGE_SEARCHBTM);
    const val = JSON.parse(values || "{}");
    return val;
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};
export const requiredShow = (required: string) => {
  if (required === REQUIRED_MANDATORY) {
    return true;
  } else {
    return false;
  }
};

const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1); // Months start at 0!
  let dd = String(today.getDate());
  if (Number(dd) < 10) dd = "0" + dd;
  if (Number(mm) < 10) mm = "0" + mm;
  const formattedToday = `${yyyy}-${mm}-${dd}`;
  return formattedToday;
};
const getDateFormat = (formatDate: Date) => {
  const today = new Date(formatDate);
  const yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1); // Months start at 0!
  let dd = String(today.getDate());
  if (Number(dd) < 10) dd = "0" + dd;
  if (Number(mm) < 10) mm = "0" + mm;
  const formattedToday = `${yyyy}-${mm}-${dd}`;
  return formattedToday;
};
const isObjectEmpty = (objectName: any) => {
  for (let prop in objectName) {
    if (objectName.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};

export {
  getMinPriceArray,
  setMinPrice,
  setMinPriceArrayObj,
  getMinMaxPrice,
  setActGeneral,
  getActGeneralArray,
  setGeneralArrayObj,
  setActBooked,
  getActBooked,
  setSearchBtmActivities,
  getSearchBtmActivities,
  setSearchBtmActivitiesObj,
  getDateTravelFormat,
  getTodayDate,
  getDateFormat,
  isObjectEmpty,
  ACTIVITY_LOCAL_STORAGE_MIN_PRICE,
  ACTIVITY_LOCAL_STORAGE_DURATION,
  ACTIVITY_LOCAL_STORAGE_START_TIME,
  ACTIVITY_LOCAL_STORAGE_SEARCHBTM,
};
