import { ReactNode, useContext, createContext, useState } from "react";

type TrasnferCheckoutType = {
  traveler: TravelerFormDataType | null;
  driverValidation: TransferTravelerValidation;
  airlineObj: TransferAirlinesType | null;
  flightNo: string;
  fliyingFrom: string;
  airlineTxt: string;
  travelerRequest: TransferTravelerRequestType;
  travelerResp: TravelersListType;
  transferInformation1: TransferJourneyInfoType;
  transferInformation2: TransferJourneyInfoType;
  flyingFromTxt: string;
  setFlyingFromTxt: (value: string) => void;
  setFlightFrom1: (value: string) => void;
  setFlightNo1: (value: string) => void;
  setFlightAirline1: (value: string) => void;
  setFlightIATACode1: (value: string) => void;
  setFlightInformationCode1: (flight: string, iata: string | null) => void;
  setFlightInformationCode2: (flight: string, iata: string | null) => void;
  setFlightFrom2: (value: string) => void;
  setFlightNo2: (value: string) => void;
  setFlightAirline2: (value: string) => void;
  setFlightIATACode2: (value: string) => void;
  setTravelerResp: (value: TravelersListType) => void;
  setTravelerRequest: (value: TransferTravelerRequestType) => void;
  setAirlineTxt: (value: string) => void;
  setAirlineObj: (value: TransferAirlinesType | null) => void;
  setFlightNo: (value: string) => void;
  setFliyingFrom: (value: string) => void;
  setDriverValidation: (value: TransferTravelerValidation) => void;
  setTraveler: (trav: TravelerFormDataType | null) => void;
  setTitleValidation: (value: string | null) => void;
  setFirstNameValidation: (value: string | null) => void;
  setLastNameValidation: (value: string | null) => void;
  setEmailValidation: (value: string | null) => void;
  setCountryCodeValidation: (value: string | null) => void;
  setPhoneValidation: (value: string | null) => void;
  setAgeValidation: (value: string | null) => void;
};

const TransferCheckoutContextDefaultValues: TrasnferCheckoutType = {
  traveler: null,
  driverValidation: {
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone: "",
    age: "",
    airlineObj: "",
    fliyingFrom: "",
    flightNo: "",
  },
  airlineObj: null,
  flightNo: "",
  fliyingFrom: "",
  airlineTxt: "",
  travelerRequest: {
    age: "",
    isd_code: "",
    trav_address1: "",
    trav_city: "",
    trav_country_code: 0,
    trav_dob: "",
    trav_email: "",
    trav_first_name: "",
    trav_gender: "",
    trav_last_name: "",
    trav_middle_name: "",
    trav_nationality: "US",
    trav_passport_expired: "",
    trav_passport_no: "",
    trav_phone: "",
    trav_postal_code: "",
    trav_state: "",
    trav_title: "",
    usr_type: 2,
    nationality_id: 0,
  },
  travelerResp: {
    address1: "",
    address2: "",
    age: "",
    city: "",
    country: "",
    country_code: "",
    date_to_birth: "",
    email: "",
    first_name: "",
    gender: "",
    id: 0,
    last_name: "",
    middle_name: "",
    nationality: "",
    passport_expiry_date: "",
    passport_no: "",
    phone: "",
    redress_no: "",
    trav_postal_code: "",
    trav_title: "",
    user_id: "",
  },
  transferInformation1: {
    flightFrom: null,
    flightNo: null,
    flightAirline: null,
    flightIATACode: null,
  },
  transferInformation2: {
    flightFrom: null,
    flightNo: null,
    flightAirline: null,
    flightIATACode: null,
  },
  flyingFromTxt: "",
  setFlyingFromTxt: () => {},
  setFlightFrom1: () => {},
  setFlightNo1: () => {},
  setFlightAirline1: () => {},
  setFlightIATACode1: () => {},
  setFlightInformationCode1: () => {},
  setFlightInformationCode2: () => {},
  setFlightFrom2: () => {},
  setFlightNo2: () => {},
  setFlightAirline2: () => {},
  setFlightIATACode2: () => {},
  setTravelerResp: () => {},
  setTravelerRequest: () => {},
  setAirlineTxt: () => {},
  setAirlineObj: () => {},
  setFlightNo: () => {},
  setFliyingFrom: () => {},
  setDriverValidation: () => {},
  setTitleValidation: () => {},
  setFirstNameValidation: () => {},
  setLastNameValidation: () => {},
  setEmailValidation: () => {},
  setCountryCodeValidation: () => {},
  setPhoneValidation: () => {},
  setAgeValidation: () => {},
  setTraveler: () => {},
};

const TrasnferCheckoutContext = createContext<TrasnferCheckoutType>(
  TransferCheckoutContextDefaultValues
);

export function useTransferCheckout() {
  return useContext(TrasnferCheckoutContext);
}

type Props = {
  children: ReactNode;
};

export function TransferCheckoutProvider({ children }: Props) {
  //States
  const [traveler, setTravelerData] = useState(
    TransferCheckoutContextDefaultValues.traveler
  );

  const [driverValidation, setDriverValidationData] =
    useState<TransferTravelerValidation>(
      TransferCheckoutContextDefaultValues.driverValidation
    );

  const [airlineObj, setAirlineObjData] = useState<TransferAirlinesType | null>(
    TransferCheckoutContextDefaultValues.airlineObj
  );

  const [airlineTxt, setAirlineTxtData] = useState<string>(
    TransferCheckoutContextDefaultValues.airlineTxt
  );

  const [flightNo, setFlightNoData] = useState(
    TransferCheckoutContextDefaultValues.flightNo
  );
  const [fliyingFrom, setFliyingFromData] = useState(
    TransferCheckoutContextDefaultValues.fliyingFrom
  );

  const [travelerRequest, setTravelerRequestData] =
    useState<TransferTravelerRequestType>(
      TransferCheckoutContextDefaultValues.travelerRequest
    );

  const [travelerResp, setTravelerRespData] = useState<TravelersListType>(
    TransferCheckoutContextDefaultValues.travelerResp
  );

  const [transferInformation1, setTransferInformation1Data] =
    useState<TransferJourneyInfoType>(
      TransferCheckoutContextDefaultValues.transferInformation1
    );

  const [transferInformation2, setTransferInformation2Data] =
    useState<TransferJourneyInfoType>(
      TransferCheckoutContextDefaultValues.transferInformation2
    );
  const [flyingFromTxt, setFlyingFromTxtData] = useState<string>(
    TransferCheckoutContextDefaultValues.flyingFromTxt
  );

  //Fill Methods
  const setTravelerRequest = (data: TransferTravelerRequestType) => {
    setTravelerRequestData(data);
  };

  const setTraveler = (data: TravelerFormDataType | null) => {
    setTravelerData(data);
  };

  const setDriverValidation = (value: TransferTravelerValidation) => {
    setDriverValidationData(value);
  };

  const setTitleValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      title: value,
    });
  };
  const setFirstNameValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      first_name: value,
    });
  };
  const setLastNameValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      last_name: value,
    });
  };
  const setEmailValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      email: value,
    });
  };
  const setCountryCodeValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      country_code: value,
    });
  };
  const setPhoneValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      phone: value,
    });
  };
  const setAgeValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      age: value,
    });
  };

  const setAirlineObj = (value: TransferAirlinesType | null) => {
    setAirlineObjData(value);
  };

  const setFlightNo = (value: string) => {
    setFlightNoData(value);
  };

  const setFliyingFrom = (value: string) => {
    setFliyingFromData(value);
  };

  const setFlyingFromTxt = (value: string) => {
    setFlyingFromTxtData(value);
  };

  const setTravelerResp = (value: TravelersListType) => {
    setTravelerRespData(value);
  };

  const setFlightFrom1 = (val: string) => {
    setTransferInformation1Data({ ...transferInformation1, flightFrom: val });
  };
  const setFlightNo1 = (val: string) => {
    setTransferInformation1Data({ ...transferInformation1, flightNo: val });
  };
  const setFlightAirline1 = (val: string) => {
    setTransferInformation1Data({
      ...transferInformation1,
      flightAirline: val,
    });
  };
  const setFlightIATACode1 = (val: string) => {
    setTransferInformation1Data({
      ...transferInformation1,
      flightIATACode: val,
    });
  };

  const setFlightInformationCode1 = (airline: string, iata: string | null) => {
    setTransferInformation1Data({
      ...transferInformation1,
      flightAirline: airline,
      flightIATACode: iata,
    });
  };

  const setFlightFrom2 = (val: string) => {
    setTransferInformation2Data({ ...transferInformation2, flightFrom: val });
  };
  const setFlightNo2 = (val: string) => {
    setTransferInformation2Data({ ...transferInformation2, flightNo: val });
  };
  const setFlightAirline2 = (val: string) => {
    setTransferInformation2Data({
      ...transferInformation2,
      flightAirline: val,
    });
  };
  const setFlightIATACode2 = (val: string) => {
    setTransferInformation2Data({
      ...transferInformation2,
      flightIATACode: val,
    });
  };

  const setFlightInformationCode2 = (airline: string, iata: string | null) => {
    setTransferInformation2Data({
      ...transferInformation2,
      flightAirline: airline,
      flightIATACode: iata,
    });
  };

  const setAirlineTxt = (value: string) => {
    setAirlineTxtData(value);
  };

  const value = {
    traveler,
    driverValidation,
    airlineObj,
    flightNo,
    fliyingFrom,
    airlineTxt,
    travelerRequest,
    travelerResp,
    transferInformation1,
    transferInformation2,
    flyingFromTxt,
    setFlyingFromTxt,
    setFlightFrom1,
    setFlightNo1,
    setFlightAirline1,
    setFlightIATACode1,
    setFlightInformationCode1,
    setFlightInformationCode2,
    setFlightFrom2,
    setFlightNo2,
    setFlightAirline2,
    setFlightIATACode2,
    setTravelerResp,
    setTravelerRequest,
    setAirlineTxt,
    setAirlineObj,
    setFlightNo,
    setFliyingFrom,
    setTraveler,
    setDriverValidation,
    setTitleValidation,
    setFirstNameValidation,
    setLastNameValidation,
    setEmailValidation,
    setCountryCodeValidation,
    setPhoneValidation,
    setAgeValidation,
  };

  TrasnferCheckoutContext.displayName = "TransferSearchDetailContext";

  return (
    <TrasnferCheckoutContext.Provider value={value}>
      {children}
    </TrasnferCheckoutContext.Provider>
  );
}
