import { checkCookies, getCookie } from "cookies-next";
import { IS_B2B, SEARCH_TYPE } from "./config";
import { getDatesFromRange, getRoomsFromPaxString } from "./helper";
import * as authHelper from "@helpers/AuthHelpers";

export const getParamsFromQueryHD = (query: any) => {
  const {
    v_id,
    language,
    search_id,
    unique_id,
    id,
    destination_id,
    search_type,
    dateRange,
    paxConfig,
    dest_type,
    Location,
    latitude,
    location,
    longitude,
    searchall,
  } = query;
  const range = getDatesFromRange(dateRange);

  return {
    vid: v_id,
    searchId: search_id,
    uniqueId: unique_id,
    id: id,
    language: language,
    startDate: range.date1,
    endDate: range.date2,
    dest_type: dest_type,
    destination_id: destination_id,
    latitude: latitude,
    longitude: longitude,
    location: location,
    paxConfig: JSON.parse(String(paxConfig || "{}")),
    search_type: search_type,
    Location: Location,
    searchAll: searchall == "true" ? true : false,
  };
};

export const getParamsFromQuery = (query: any) => {
  const {
    dateRange,
    dest_type,
    destination_id,
    location,
    latitude,
    longitude,
    paxConfig,
    search_type,
  } = query;

  const range = getDatesFromRange(dateRange);
  const datesFormated = getDatesFromRange(String(dateRange));

  return {
    startDate: range.date1,
    endDate: range.date2,
    dest_type: dest_type,
    destination_id: destination_id,
    latitude: latitude,
    longitude: longitude,
    location: location,
    paxConfig: JSON.parse(String(paxConfig || "{}")),
    search_type: search_type,
    datesFormated,
  };
};

export const getNumOfRooms = (paxConfig: string) => {
  try {
    let jsonPaxConfig = JSON.parse(paxConfig);
    if (!Array.isArray(jsonPaxConfig)) {
      return 1;
    }
    let rooms = 1;
    jsonPaxConfig.map((objPax: any) => {
      rooms = objPax?.room;
    });

    return rooms;
  } catch (error) {
    return 1;
  }
};

interface SearchReq extends SearchType {
  userType: number;
  nationalityCode?: string;
  agentSelectedId?: number;
  packageCheked?: boolean;
  isVr?: boolean;
  UM?: string;
}

export const createReqSearcByRoomOccupancy = (
  dataReq: SearchReq | null
): RequestSearchByRoomOccupancy | null => {
  if (!dataReq) return null;

  const {
    search_type,
    destination_id,
    userType,
    nationalityCode,
    datesFormated,
    agentSelectedId,
    packageCheked,
    paxConfig,
    isVr,
    location,
    UM,
  } = dataReq;

  const check_in_date = datesFormated?.date1;
  const check_out_date = datesFormated?.date2;
  if (!check_in_date || !check_out_date) {
    return null;
  }
  const searchTypeArray = `${SEARCH_TYPE}`.split(",");
  const nationalityUser = authHelper.getNationality();
  const nationality =
    IS_B2B && userType == 1
      ? nationalityCode
        ? nationalityCode
        : nationalityUser
      : nationalityUser;
  const currency = checkCookies("currency_code")
    ? String(getCookie("currency_code"))
    : "USD";

  const language = getCookie("lan_code")
    ? String(getCookie("lan_code") || "en-US")
    : "en-US";
  const vid: any = [
    searchTypeArray.includes(String(search_type)) ? String(destination_id) : "",
  ];
  const req = {
    search_text: searchTypeArray.includes(String(search_type))
      ? "100"
      : String(destination_id),
    search_type: Number(search_type),
    nationality,
    currency: currency?.toUpperCase(),

    check_in_date,
    check_out_date,
    language,
    traveller_id:
      IS_B2B && userType == 1 ? String(agentSelectedId || null) : null,
    destination_name: String(location),
    minimum_stay: datesFormated?.diff,
    usr_type: userType,
    is_package_rate:
      IS_B2B && userType == 1 ? (packageCheked ? "true" : "false") : "false",
    distance: UM || "ml",
    rooms: getRoomsFromPaxString(paxConfig),
    v_id: vid,
    display_price: "With Tax",
    is_vr: isVr ? "true" : "false", //true solo resorts/vacationsrentals
  };

  return req;
};
interface SearchFilterReq {
  search_text?: string;
  search_type?: number;
  userType: number;
  nationality?: string;
  agentSelectedId?: number;
  packageCheked?: boolean;
  isVr?: boolean;
  traveller_id?: number;
  currency?: string;
  check_in_date?: string;
  check_out_date?: string;
  language?: string;
  rooms?: {
    adult_count: number;
    childs: any;
  }[];
  filters: GeneralFilterType;
  offset: number;
  destination_name?: string;
  display_price?: string;
  uid: string;
  orderBy: {
    key: string;
    order: string;
  };

  sort_on?: string;
  sort_by?: string;
}

export const createReqFilterSearcByRoomOccupancy = ({
  search_text,
  search_type,
  currency,
  traveller_id,
  nationality,
  check_in_date,
  check_out_date,
  language,
  rooms,
  filters,
  offset = 10,
  destination_name,
  display_price,
  uid,
  orderBy,
}: SearchFilterReq) => {
  const sort_onAux = orderBy.key;
  const sort_byAux = orderBy.order;

  const json = {
    filterreq: {
      search_text: search_text,
      search_type: search_type,
      traveller_id: traveller_id,
      nationality: nationality,
      currency: currency,
      check_in_date: check_in_date,
      check_out_date: check_out_date,
      language: language,
      rooms,
      no_availablility: "true",
      filter: {
        ...filters,
        offset: offset,
        sort_on: filters.sort_on ? filters.sort_on : sort_onAux,
        sort_by: filters.sort_by ? filters.sort_by : sort_byAux,
      },
      destination_name: destination_name,
      display_price,
    },
    uidreq: uid,
  };
  return json;
};

export const getDistanceLabelForCards = (property: {
  distance: string;
  selectedLandMark: HotelLandmark | null;
  showMapView: boolean;
  latitude: number;
  longitude: number;
  discount: number;
  isWeeks: boolean;
}) => {
  var distanceFinal = "";
  if (property?.selectedLandMark) {
    var resultDistanse = 0;
    var resultDistanse = Number(
      getDistance({
        fromLat: Number(property.selectedLandMark.lat_long.split(",")[0] || 0),
        fromLng: Number(property.selectedLandMark.lat_long.split(",")[1]) || 0,
        toLat: Number(property.latitude || 0),
        toLng: Number(property.longitude || 0),
      }) || 0
    );
    var distanceConvert: number =
      resultDistanse > 1 ? resultDistanse : resultDistanse * 1000;
    var type = resultDistanse > 1 ? "km" : "m";

    distanceFinal =
      distanceConvert + " " + type + " from " + property.selectedLandMark.name;
  } else {
    distanceFinal = property.distance;
  }

  return `${distanceFinal}`;
};

export const getDistance = ({
  fromLat,
  fromLng,
  toLat,
  toLng,
}: getDistanceProps) => {
  const rad = function (x: any) {
    return (x * Math.PI) / 180;
  };

  const R = 6378.137; // Earth radius in km (WGS84)
  const dLat = rad(toLat - fromLat);
  const dLong = rad(toLng - fromLng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(fromLat)) *
      Math.cos(rad(toLat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d.toFixed(3);
};

export const checkPriceInfoJSON = (inData: PriceType) => {
  let pasa: boolean = true;

  if (!inData.hasOwnProperty("currency")) {
    pasa = false;
  }
  if (!inData.hasOwnProperty("inclusive")) {
    pasa = false;
  }
  if (!inData.hasOwnProperty("exclusive")) {
    pasa = false;
  }
  if (!inData.hasOwnProperty("net_rate")) {
    pasa = false;
  }
  if (!inData.hasOwnProperty("per_night")) {
    pasa = false;
  }
  if (!inData.hasOwnProperty("supplier_code")) {
    pasa = false;
  }
  if (pasa) {
    var exclusive = Number(inData?.exclusive || "0");
    var inclusive = Number(inData?.inclusive || "0");
    if (exclusive == 0 && inclusive == 0) {
      pasa = false;
    }
  }

  /*  console.log(
    "🚀 ~ file: helperHotel.ts:331 ~ checkPriceInfoJSON ~ pasa:",
    pasa
  );   */
  return pasa;
};

export const enabledBookingBtn = (price?: PriceType) => {
  var pasa: boolean = true;
  var exclusive = Number(price?.exclusive || "0");
  var inclusive = Number(price?.inclusive || "0");
  if (
    (!price?.per_night && !price?.tax) ||
    (exclusive == 0 && inclusive == 0)
  ) {
    pasa = false;
  }
  /* console.log(
    "🚀 ~ file: helperHotel.ts:348 ~ enabledBookingBtn ~ pasa:",
    pasa
  ); */
  return pasa;
};

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const getArrayDates = (
  startDate: Date,
  endDate: Date,
  checkIn: string,
  checkOut: string
) => {
  const dateArray: HotelDateCheckBoxAvailableType[] = [];
  const dateInit = new Date(checkIn);
  const dateFin = new Date(checkOut);
  const currentDate = dateInit > startDate ? new Date(startDate) : dateInit;
  const lastDate = dateFin > endDate ? dateFin : new Date(endDate);
  let i = 0;
  while (currentDate <= lastDate) {
    dateArray.push({
      id: String(i),
      checked: false,
      label: formatDate(currentDate),
      labelDate: true,
      labelText: "",
    });
    currentDate.setDate(currentDate.getDate() + 1);
    i++;
  }

  return dateArray;
};
