export const compareValues = (key: any, order = "asc", forceNumber = false) => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA =
      typeof a[key] == "string" && !forceNumber
        ? a[key].toUpperCase()
        : Number(a[key]);
    const varB =
      typeof b[key] == "string" && !forceNumber
        ? b[key].toUpperCase()
        : Number(b[key]);

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order == "desc" ? comparison * -1 : comparison;
  };
};

export const compareDateValues = (key: any, order = "asc") => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = Number(new Date(a[key]).getTime());

    const varB = Number(new Date(b[key]).getTime());

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order == "desc" ? comparison * -1 : comparison;
  };
};

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

export const sortObjectByKeys = (o: any) => {
  return Object.keys(o)
    .sort()
    .reduce((r: any, k: any) => ((r[k] = o[k]), r), {});
};
